import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/lupa.png";
import Methods from "../methods/methods";
import { delete_catalogs } from "../../../services/catalogs/catalogs";
import DELETE from "../../../imgComponents/borrar.png";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

  const handle_delete_data = async (id_value) => {
    props?.setmodalT(true);
    try {
      var res = await delete_catalogs(props.tablename, id_value);
      props?.alerts("Successfully Removed", true);
      props?.handlereconsult(true);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      props?.alerts("Error Deleting", false);
      props?.setmodalT(false);
    }
  };



    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    const result = await CustomDialog(
                        <Methods
                            id_data={props?.id_data} 
                            response={props?.response} 
                            response2={props?.response2} 
                            response3={props?.response3} 
                            value={props?.value} 
                            valuetwo={props?.valuetwo} 
                            valuethree={props?.valuethree} 
                            tablename={props?.tablename} 
                            columdata={props?.columdata} 
                            visibilitydate={props?.visibilitydate} 
                            method={"put"} 
                            alerts={props?.alerts} 
                            reconsultar={props?.reconsultar}
                        ></Methods>,
                        {
                            className: "custom-modal-3",
                            title: "Update",
                            showCloseIcon: true,
                        }
                        
                    );
                  } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Update"
                        className=""
                    ></img>
                </ListItemIcon>
                Update
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    const result = await Confirm(
                            "Are you sure to delete the attribute?",
                            "DELETE",
                            "YES",
                            "No"
                          );
                          if (result) {
                            handle_delete_data(props?.row.original.id);
                          } else {}
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={DELETE}
                        title="Delete"
                        className=""
                    ></img>
                </ListItemIcon>
                Delete
            </MenuItem>            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
