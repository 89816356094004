//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import Modal_Table from "../../main_components/modal/modal_Table";
//ESTILOS
import "./mps.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { NEW_RECORD } from "./new_record";
import { SelectColumnFilter } from "../../componentsCFDI/filters";
import datajson from "./datamps.json";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";


const MPS= () => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");


  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
 
  const URL_BASE = "https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com";
  
useEffect(()=>{
  handleSubmit();
},[]);

function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n==true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
const handleStateChange = (newState) => {
  const filteredRowsCount = newState.filteredRowModel
  settotal_rows(filteredRowsCount);
};



//proceso de consultar todos los ROLES
// const handleSubmit =  () => {  
//     if(entrada==0){
//       entrada=entrada+1;
//       setmenssage("Consultando Roles...");
//       setmodalGeneral(true);
//       setmodalT(true);
//     }else{
//       setmodalT(true);
//     }
//     setopen(true);
//     setData([]);

//     setIsLoading(true);
//     setTimeout(() => {
//       axios
//         .get(
//           `${URL_BASE}/development/roles`,
//           {
//             headers: {
//               Authorization: sessionStorage.getItem("getIdTokenRefresh"),
//               "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
//               "Content-Type": "application/json",
//             },
//           } 
//         )
//         .then((response) => {
//           console.log(response.data);
//           const data = response.data.roles;
//           setData(data);
//           setopen(false);
//           setmodalGeneral(false);
//           setmodalT(false);
//           setIsLoading(false);
//         })
//         .catch((err) => {
//           console.log("error", err);
//           setData([]);
//           setopen(false);
//           setmodalGeneral(false);
//           setmodalT(false);
//           setIsLoading(false);
//         });
//     }, 2000);
// };


const handleSubmit = () =>{
  try{
    setData(datajson)
  }catch(err){
    console.log(err);
  }
}

const columns = useMemo(
  () => [
    // {
    //   header:()=>(
    //     <div>
    //       <img
    //         id="crearRol"
    //         src={CREATE}
    //         onClick={async () => {
    //           const result = await CustomDialog(               
    //          <div>
    //             <NEW_RECORD handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></NEW_RECORD>
    //             {/* <CreateRole handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateRole> */}
    //          </div>,
    //        {
    //          className: "modalish",
    //          title:"New Record",
    //          showCloseIcon: true,
    //          isCanClose:false,
    //        }
    //    );
    //  }}
    //         title="New Record"
    //         className="cancel"
    //       ></img>
    //     </div>
    //   ),
      
    //   id: "Acciones",
    //   Cell: ({ valor, row }) => {
    //     return (
    //       <div>
    //         <img
    //           src={Edit}
    //           onClick={async () => {
    //               const result = await CustomDialog(               
    //               <div>
    //                   <NEW_RECORD operation={"EDIT"} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar} row={row}></NEW_RECORD>
                      
    //               </div>,
    //               {
    //                 className: "modalish",
    //                 title:"New Record",
    //                 showCloseIcon: true,
    //                 isCanClose:false,
    //               }
    //             );
    //             }         
    //             }
    //           className="centerText, cancel"
    //           title="Eliminar Rol"
    //         ></img>
    //       </div>
    //     );
    //   },
    // },
    {
      header: "COUNTRY",
      id: "COUNTRY",
      accessorKey: "CTRY",
      // Filter: SelectColumnFilter
    },
    {
      header: "BRAND",
      id: "BRAND",
      accessorKey: "BRAND"
    },
    {
      header: "PLATFORM",
      id: "PLATFORM",
      accessorKey: "PLTFRM"
    },
    {
      header: "PROGRAM",
      id: "PROGRAM",
      accessorKey: "PRGRM"
    },
    {
      header: "VEHICLE",
      id: "VEHICLE",
      accessorKey: "VEHICLE"
    },
    {
      header: "SAP ITEM",
      id: "SAP ITEM",
      accessorKey: "SAP ITEM"
    },
    {
      header: "MPG",
      id: "MPG",
      accessorKey: "MPG"
    },
    {
      header: "APP",
      id: "APP",
      accessorKey: "APP"
    },
    {
      header: "CUST",
      id: "CUST",
      accessorKey: "CUST"
    },
    {
      header: "SOP",
      id: "SOP",
      accessorKey: "SOP"
    },
    {
      header: "EOP",
      id: "EOP",
      accessorKey: "EOP"
    },
    {
      header: "PROJECT STATUS",
      id: "PROJECT STATUS",
      accessorKey: "Project Status",
      // Filter: SelectColumnFilter
    },
    {
      header: "DIV",
      id: "DIV",
      accessorKey: "Div",
      // Filter: SelectColumnFilter,
    },
    {
      header: "ADJUST FACTOR (%)",
      id: "ADJUST",
      accessorKey: "Adjust factor (%)"
    },
    {
      header: "NOTES",
      id:"NOTES",
      accessorKey: "NOTES"
    },
    {
      header: "MIN SERVICE",
      id: "MINSERV",
      accessorKey: "Min Servicio",
      // Filter: SelectColumnFilter,
    },
    {
      header: "BOM",
      id: "BOM",
      accessorKey: "BOM"
    },
    {
      header: "2023",
      id: "2023",
      accessorKey: "2023"
    },
    {
      header: "2024",
      id: "2024",
      accessorKey: "2024"
    },
    {
      header: "2025",
      id: "2025",
      accessorKey: "2025"
    },
    {
      header: "Q 2023",
      id: "Q2023",
      accessorKey: "Q 2023"
    },
    {
      header: "Q 2024",
      id: "Q2024",
      accessorKey: "Q 2024"
    },
    {
      header: "Q 2025",
      id: "Q2025",
      accessorKey: "Q 2025"
    },
    {
      header: "Mth 2023",
      id: "mth2023",
      accessorKey: "Mth 2023"
    },
    {
      header: "Mth 2024",
      id: "mth2024",
      accessorKey: "Mth 2024"
    },
    {
      header: "Mth 2025",
      id :"mth2025",
      accessorKey: "Mth 2025"
    },
    {
      header: "MQ 2023",
      id: "MQ2023",
      accessorKey: "MQ 2023"
    },
    {
      header: "MQ 2024",
      id: "MQ2024",
      accessorKey: "MQ 2024"
    },
    {
      header: "MQ 2025",
      id: "MQ2025",
      accessorKey: "MQ 2025"
    }
    
    ],
    []
);


const update_identifiers = [
  { CTRY: 'string' },
  { BRAND: 'string' },
  { PLTFRM: 'string' },
  { PRGRM: 'string' },
  { VEHICLE: 'string' },
  { 'SAP ITEM': 'string' },
  { MPG: 'string' },
  { APP: 'string' },
  { CUST: 'string' },
  { SOP: 'string' },
  { EOP: 'string' },
  { 'Project Status': 'string' },
  { Div: 'string' },
  { 'Adjust factor (%)': 'string' },
  { NOTES: 'string' },
  { 'Min Servicio': 'string' },
  { BOM: 'string' },
  { '2023': 'string' },
  { '2024': 'string' },
  { '2025': 'string' },
  { 'Q 2023': 'string' },
  { 'Q 2024': 'string' },
  { 'Q 2025': 'string' },
  { 'Mth 2023': 'string' },
  { 'Mth 2024': 'string' },
  { 'Mth 2025': 'string' },
  { 'MQ 2023': 'string' },
  { 'MQ 2024': 'string' },
  { 'MQ 2025': 'string' }
];


  return (
    <>

      <BREADCRUMBS
        niveles={[
          { label: "MPS", path: null },
          { label: "Minimos y FCST", path: "min_fcst"}
        ]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>

  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
          <div className="new_table_v2">
 
          <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyheader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Upload File"
                        src={CREATE}
                        style={{cursor:"pointer"}}
                        className=""
                        onClick={async () => {
                          const result = await CustomDialog(
                            <NEW_RECORD 
                              handleSubmit={handleSubmit} 
                              alertas={alertas} 
                              reconsultar={reconsultar}
                            ></NEW_RECORD>,
                            {
                              className: "custom-modal-3",
                              title: "Upload File",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        }}
                      ></img>
                    </div>
                  ),
                  enableHiding: true
                },
                'mrt-row-actions': {
                  enableHiding: true
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  handleSubmit={handleSubmit}
                  alertas={alertas}
                  reconsultar={handleSubmit}
                />
              ]}
              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"ISH"}
                  update_identifiers={update_identifiers}
                  key={index}
                />
               )
               }
            />
          </div>

          </div>
    </>
  );
}

export default MPS;
