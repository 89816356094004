//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { alpha, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import { get_ihs} from "../../services/IHS/ihs";
import Upload_file from "./upload_file";
//ESTILOS
import { upperCase } from "lodash";
import "../tableclientes.css"
import CREATE from "../../imgComponents/create.png";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import "./ish.css";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const TableISH = (props) => {
  
  const notificationSystemRef = useRef();
  const [data, setData] = React.useState([]);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [open, setopen]=useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");


  

  useEffect(() => {
    handledata();
  }, []);

  const handledata = async () => {
    setIsLoading(true);
    setData([]);
    try {
      setopen(true);
      setmodalT(true);  
      var res = await get_ihs(props.tablename);
      res.sort((a, b) => b.id - a.id);
      setData(res);
      setopen(false);
      setmodalT(false);
      setIsLoading(false);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      setData([]);
      setopen(false);
      setIsLoading(false);
      setmodalT(false);
    }
  };

 

  const handlereconsult=(valid)=>{
    if(valid===true){
      setData([]);
      handledata();
    }else{
      
    }
  }

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  const columns = useMemo(
    () => [
      {
        header: `${upperCase("country")}`,
        accessorKey: `country`,
        id: "country"
      },
      {
        header: `${upperCase("production plant")}`,
        accessorKey: `production_plant`,
        id: "production_plant"
      },
      {
        header: `${upperCase("production brand")}`,
        accessorKey: `production_brand`,
        id: "production_brand"
      },
      {
        header: `${upperCase("platform")}`,
        accessorKey: `platform`,
        id: "platform"
      },
      {
        header: `${upperCase("program")}`,
        accessorKey: `program`,
        id: "program"
      },
      {
        header: `${upperCase("production nameplate")}`,
        accessorKey: `production_nameplate`,
        id: "production_nameplate"
      },
      {
        header: `${upperCase("sop")}`,
        accessorKey: `sop`,
        id: "sop"
      },
      {
        header: `${upperCase("eop")}`,
        accessorKey: `eop`,
        id: "eop"
      },
      {
        header: `${upperCase("lifecycle")}`,
        accessorKey: `lifecycle`,
        id: "lifecycle"
      },
      {
        header: `${upperCase("vehicle")}`,
        accessorKey: `vehicle`,
        id: "vehicle"
      },
      {
        header: `${upperCase("assembly type")}`,
        accessorKey: `assembly_type`,
        id: "assembly_type"
      },
      {
        header: `${upperCase("strategic group")}`,
        accessorKey: `strategic_group`,
        id: "strategic_group"
      },
      {
        header: `${upperCase("sales group")}`,
        accessorKey: `sales_group`,
        id: "sales_group"
      },
      {
        header: `${upperCase("global nameplate")}`,
        accessorKey: `global_nameplate`,
        id: "global_nameplate"
      },
      {
        header: `${upperCase("primary design center")}`,
        accessorKey: `primary_design_center`,
        id: "primary_design_center",
        size: 250
      },
      {
        header: `${upperCase("primary design country")}`,
        accessorKey: `primary_design_country`,
        size: 250,
        id: "primary_design_country"
      },
      {
        header: `${upperCase("primary design region")}`,
        accessorKey: `primary_design_region`,
        id: "primary_design_region",
        size: 250
      },
      {
        header: `${upperCase("secondary_design_center")}`,
        accessorKey: `secondary_design_center`,
        id: "secondary_design_center",
        size: 250
      },
      {
        header: `${upperCase("secondary_design_country")}`,
        accessorKey: `secondary_design_country`,
        id: "secondary_design_country",
        size: 250
      },
      {
        header: `${upperCase("secondary_design_region")}`,
        accessorKey: `secondary_design_region`,
        id: "secondary_design_region",
        size: 250
      },
      {
        header: `${upperCase("gvw_rating")}`,
        accessorKey: `gvw_rating`,
        id: "gvw_rating"
      },
      {
        header: `${upperCase("gvw_class")}`,
        accessorKey: `gvw_class`,
        id: "gvw_class"
      },
      {
        header: `${upperCase("car/truck")}`,
        accessorKey: `car`,
        id: "car"
      },
      {
        header: `${upperCase("production_type")}`,
        accessorKey: `production_type`,
        id: "production_type"
      },
     
      {
        header: `${upperCase("global_production_segment")}`,
        accessorKey: `global_production_segment`,
        size: 250,
        id: "global_production_segment"
      },
      {
        header: `${upperCase("regional_sales_segment")}`,
        accessorKey: `regional_sales_segment`,
        size: 250,
        id: "regional_sales_segment"
      },
      {
        header: `${upperCase("global_production_price_class")}`,
        accessorKey: `global_production_price_class`,
        size: 250,
        id: "global_production_price_class"
      },
      {
        header: `${upperCase("global_sales_segment")}`,
        accessorKey: `global_sales_segment`,
        size: 250,
        id: "global_sales_segment"
      },
      {
        header: `${upperCase("global_sales_sub-segment")}`,
        accessorKey: `global_sales_sub-segment`,
        id: "global_sales_sub-segment"
      },
      {
        header: `${upperCase("global_sales_price_class")}`,
        accessorKey: `global_sales_price_class`,
        id: "global_sales_price_class"
      },
      {
        header: `${upperCase("short-term_risk_rating")}`,
        accessorKey: `short-term_risk_rating`,
        id: "short-term_risk_rating"
      },
      {
        header: `${upperCase("long-term_risk_rating")}`,
        accessorKey: `long-term_risk_rating`,
        id: "long-term_risk_rating"
      },
      {
        header: `${upperCase(`cy_202${3}`)}`,
        accessorKey: `cy_202${3}`,
      },
      {
        header: `${upperCase(`cy_202${4}`)}`,
        accessorKey: `cy_202${4}`,
      },
      {
        header: `${upperCase(`cy_202${5}`)}`,
        accessorKey: `cy_202${5}`,
      },
      {
        header: `${upperCase(`cy_202${6}`)}`,
        accessorKey: `cy_202${6}`,
      },

      
    ],
    [data]
  );
  
  const update_identifiers = [
    { country: 'string' },
    { production_plant: 'string' },
    { production_brand: 'string' },
    { platform: 'string' },
    { program: 'string' },
    { production_nameplate: 'string' },
    { sop: 'string' },
    { eop: 'string' },
    { lifecycle: 'string' },
    { vehicle: 'string' },
    { assembly_type: 'string' },
    { strategic_group: 'string' },
    { sales_group: 'string' },
    { global_nameplate: 'string' },
    { primary_design_center: 'string' },
    { primary_design_country: 'string' },
    { primary_design_region: 'string' },
    { secondary_design_center: 'string' },
    { secondary_design_country: 'string' },
    { secondary_design_region: 'string' },
    { gvw_rating: 'string' },
    { gvw_class: 'string' },
    { car: 'string' },
    { production_type: 'string' },
    { global_production_segment: 'string' },
    { regional_sales_segment: 'string' },
    { global_production_price_class: 'string' },
    { global_sales_segment: 'string' },
    { global_sales_sub_segment: 'string' },
    { global_sales_price_class: 'string' },
    { short_term_risk_rating: 'string' },
    { long_term_risk_rating: 'string' },
  ];

  return (
    <div className="table-ihs">
      {/* <Modal_Table
      open={true}
      message={menssage}
      modalGeneral={modalGeneral}
      modalT={modalT}
    ></Modal_Table> */}

    <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CUENTAS CONTABLES", path: null },
        ]
      }
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
    ></BREADCRUMBS>

   <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
      
    <div className="new_table_v2">
        <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              // enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Upload File"
                        src={CREATE}
                        style={{cursor:"pointer"}}
                        className=""
                        onClick={async () => {
                          const result = await CustomDialog(
                            <Upload_file></Upload_file>,
                            {
                              className: "custom-modal-2",
                              title: "Upload File",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                        }}
                      ></img>
                    </div>
                  ),
                  enableHiding: true
                },
                'mrt-row-actions': {
                  enableHiding: true
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }

              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"ISH"}
                  update_identifiers={update_identifiers}
                  key={index}
                />
               )
               }
            />
    </div>
        
    </div>
  );
};

export default TableISH;
