import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../imgComponents/lupa.png";
import BlemSales from "../AnualSales";


const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <BlemSales></BlemSales>,
                        {
                            className: "custom-modal-3",
                            title: "SUMMARY",
                            showCloseIcon: true,
                        }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Summary"
                        className=""
                    ></img>
                </ListItemIcon>
                Summary
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
