import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../imgComponents/save.png";
import { NEW_RECORD } from "../new_record";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    return (
        <div>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <NEW_RECORD
                            operation={"EDIT"} 
                            handleSubmit={props?.handleSubmit} 
                            alertas={props?.alertas} 
                            reconsultar={props?.reconsultar} 
                            row={props?.row}
                        ></NEW_RECORD>
                        ,
                    {
                        className: "custom-modal",
                        title: "EDITAR CUENTA CONTABLE",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Registro"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Registro
            </MenuItem>
            
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
