//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import Modal_Table from "../main_components/modal/modal_Table";
//ESTILOS
import "../componentsCFDI/estilo.css";
import "./tableclientes.css";
import "../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import DELETE from "../imgComponents/borrar.png";
import Spinner from "../imgComponents/S.gif";
import CREATE from "../imgComponents/create.png";
import CODEQR from "../imgComponents/codigoqr.png";
import SUCCESSFULLY from "../imgComponents/successfully.png";
import DemoJson from "../dataComponets/demo.json";
import { Style } from "@material-ui/icons";
import VER from "../imgComponents/visualizar.png";
import ADD from "../imgComponents/add.png";
import PDF from "../imgComponents/pdf.png";
import AnualSales from "./AnualSales";
import { Graphics } from "./Graphics";
import "./graphic.css";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";



const ProjectList = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  console.log(DemoJson);

  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");



  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData(DemoJson);
    setIsLoding(true);
    setmodalT(false);

    setmodalGeneral(false);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  
  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };
  
  const columns = useMemo(
    () => [
      //  {
      //    id: "expander",
      //    header: "Acciones",
      //    Cell: ({ row }) => {
           
      //      return (
      //       <img
      //         title="Summary"
      //         src={SAVE}
      //         onClick={async () => {
      //           const result = await CustomDialog(<AnualSales></AnualSales>, {
      //             className: "modalDemo",
      //             title: "Summary",
      //             showCloseIcon: true,
      //             isCanClose: false,
      //           });
      //           {
      //             handleReconsulta(result,"Summary");
      //           }
      //         }}
      //       ></img>
      //      );
      //    },
      //  },
      {
        id: "headprimary",
        header: " ",
        columns: [
          {
            header: " ",
            id: "headersecondary",
            columns: [
              {
                header: "Warehouse",
                accessorKey: "warehouse",
                id: "warehouse",
              },
              {
                id: "id",
                header: "#",
                accessorKey: "id",
              },
              {
                id: "resp",
                header: "Resp",
                accessorKey: "resp",
              },
              {
                id: "crm",
                header: "CRM",
                accessorKey: "CRM",
              },
              {
                id: "closingchance",
                header: "Closing Chance",
                accessorKey: "closing_chance",
                filter: "equals",
              },
              {
                id: "customer",
                header: "Customer",
                accessorKey: "customer",
              },
              {
                id: "mpg",
                header: "MPG",
                accessorKey: "MPG",
              },
              {
                id: "reference",
                header: "Reference",
                accessorKey: "reference",
              },
              {
                id: "application",
                header: "Application",
                accessorKey: "application",
              },
              {
                id: "carryover",
                header: "Carry Over",
                accessorKey: "carry_over",
              },
              {
                id: "sector",
                header: "Sector",
                accessorKey: "sector",
              },
              {
                id: "productionbrand",
                header: "Production Brand",
                accessorKey: "production_brand",
              },
              {
                id: "nameplate",
                header: "Name Plate",
                accessorKey: "name_plate",
              },
              {
                id: "country",
                header: "Country",
                accessorKey: "country",
              },
              {
                id: "program",
                header: "Program",
                accessorKey: "program",
              },
              {
                id: "platform",
                header: "Platfrom",
                accessorKey: "platform",
              },

            ]
          }
        ],
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
      },
      {
        id: "AnnualSales",
        header: "Annual Sales",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: "#9D1313",
          },
        }),
        columns: [
          {
            header: "2023",
            id: "anualsales2023",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#9D1313",
              },
            }),
            columns: [
              {
                id: "anualsales2023margin",
                header: "Margin",
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
                accessorKey: "annual_salesyear_2023margin",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2023.margin}</div>;
                },
              },
              {
                id: "anualsales2023sales",
                header: "Sales",
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),      
                accessorKey: "annual_salesyear_2023sales",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2023.sales}</div>;
                },
              },
              {
                id: "anualsales2023p",
                header: "%",
                accessorKey: "annual_salesyear_2023sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.annual_sales.year_2023.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),        
              },
            ],
          },
          {
            header: "2024",
            id: "anualsales2024",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#9D1313",
              },
            }),    
            columns: [
              {
                id: "anualsales2024Margin",
                header: "Margin",
                accessorKey: "annual_sales.year_2024.margin",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2024.margin}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
                
              },
              {
                id: "anualsales2024Sales",
                header: "Sales ",
                accessorKey: "annual_sales.year_2024.sales",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2024.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
              {
                id: "anualsales2024p",
                header: "% ",
                accessorKey: "annual_sales.year_2024.sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.annual_sales.year_2024.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
            ],
          },
          {
            id: "anualsales2025",
            header: "2025",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#9D1313",
              },
            }),
            columns: [
              {
                header: "anualsales2025Margin",
                accessorKey: "annual_salesyear_2024margin",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2024.margin}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
              {
                header: "anualsales2025Sales",
                accessorKey: "annual_salesyear_2025sales",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2025.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
              {
                header: "anualsales2025p",
                accessorKey: "annual_salesyear_2025sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.annual_sales.year_2025.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
            ],
          },
        ],
      },
      {
        id: "BlendedSales",
        header: "Blended Sales",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: "#3F0E4E",
          },
        }),
        columns: [
          {
            id: "BlendedSales2023",
            header: "2023",
            enableFilters: false,
            enableEditing: false,
            enableRowOrdering: false,
            enableGrouping: false,
            enableColumnDragging: false,
            enableColumnActions: false,
            enableFilterMatchHighlighting: false,
            enableColumnFilterModes: false,
            enableColumnFilter: false,
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#3F0E4E",
              },
            }),
            columns: [
              {
                id: "BlendedSales2023Margin",
                header: " Margin",
                accessorKey: "blended_sales.year_2023.margin",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2023.margin}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "BlendedSales2023Sales",
                header: " Sales",
                accessorKey: "blended_sales.year_2023.sales",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2023.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "BlendedSales202p",
                header: "%",
                accessorKey: "blended_sales.year_2023.sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2023.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
            ],
          },
          {
            id: "BlendedSales2024",
            header: "2024",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#3F0E4E",
              },
            }),
            columns: [
              {
                id: "BlendedSales2024Margin",
                header: " Margin  ",
                accessorKey: "blended_sales.year_2024.margin",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2024.margin}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "BlendedSales2024sales",
                header: "Sales",
                accessorKey: "blended_sales.year_2024.sales",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2024.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "BlendedSales2024p",
                header: "%",
                accessorKey: "blended_sales.year_2024.sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2024.sales_porcentaje}</div>
                  );
                  
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
            ],
          },
          {
            id: "BlendedSales2025",
            header: "2025",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#3F0E4E",
              },
            }),
            columns: [
              {
                id: "BlendedSales2025margin",
                header: "Margin",
                accessorKey: "blended_sales.year_2024.margin",
                accessorFn: (d)=>{
                  return <div>{d?.blended_sales.year_2024.margin}</div>
                },
                muiTableHeadCellProps: () =>({
                  sx: {
                    backgroundColor: "#3F0E4E"
                  }
                })
              },
              {
                id: "BlendedSales2025sales",
                header: "Sales",
                accessorKey: "blended_sales.year_2025.sales",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2025.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "BlendedSales2025p",
                header: "%",
                accessorKey: "blended_sales.year_2025.sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2025.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
            ],
          },
        ],
      },
      {
        id: "BOMH",
        header: "BOM",
        columns: [
          {
            id: "BOMHH",
            header: " ",
            columns: [
              {
                id: "BOM",
                header: " ",
                accessorKey: "DOM"
              },
            ],
          },
        ],
      },
      {
        id: "Vehicles/Years",
        header: "Vehicles/Years",
        columns: [
          {
            id: "[k]",
            header: "[K]",
            columns: [
              {
                id: "Vehicles/Years2023",
                header: "2023",
                accessorKey: "vehicles_year.year_2023.vy",
                accessorFn: (d) => {
                  return <div>{d?.vehicles_year.year_2023.vy}</div>;
                },
              },
              {
                id: "Vehicles/Years2024",
                header: "2024",
                accessorKey: "vehicles_year.year_2023.vy",
                accessorFn: (d) => {
                  return <div>{d?.vehicles_year.year_2023.vy}</div>;
                },
              },
              {
                id: "Vehicles/Years2025",
                header: "2025",
                accessorKey: "vehicles_year.year_2023.vy",
                accessorFn: (d) => {
                  return <div>{d?.vehicles_year.year_2023.vy}</div>;
                },
              },
            ],
          },
        ],
      },
      {
        id: "Pces/Year",
        header: "Pces/Year",
        columns: [
          {
            id: "Pces/Year[k]",
            header: "[K] ",
            columns: [
              {
                id: "Pces/Year2023",
                header: "2023 ",
                accessorKey: "pieces_year.year_2023.pieces",
                accessorFn: (d) => {
                  return <div>{d?.pieces_year.year_2023.pieces}</div>;
                },
              },
              {
                id: "Pces/Year2024",
                header: "2024 ",
                accessorKey: "pieces_year.year_2023.pieces",
                accessorFn: (d) => {
                  return <div>{d?.pieces_year.year_2023.pieces}</div>;
                },
              },
              {
                id: "Pces/Year2025",
                header: "2025 ",
                accessorKey: "pieces_year.year_2023.pieces",
                accessorFn: (d) => {
                  return <div>{d?.pieces_year.year_2023.pieces}</div>;
                },
              },
             ],
          },
        ],
      },
      {
        id: "BIP",
        header: "BIP",
        columns: [
          {
            id: "biph",
            header: " ",
            columns: [
              {
                id: "biphbip",
                header: "  ",
                accessorKey: "BIP",
              },
            ],
          },
        ],
      },
      {
        id: "SUPP",
        header: "SUPP",
        columns: [
          {
            id: "suph",
            header: "  ",
            columns: [
              {
                id: "suphsupp",
                header: " ",
                accessorKey: "Supp",
              },
            ],
          },
        ],
      },
      {
        id: "ifh",
        header: "I_F",
        columns: [
          {
            id: "ifhh",
            header: "   ",
            columns: [
              {
                id: "if",
                header: "    ",
                accessorKey: "I_F",
              },
            ],
          },
        ],
      },
      {
        id: "sellprice",
        header: "SELL/PRICE",
        columns: [
          {
            id: "sellprice[usdk]",
            header: "[USD/K]",
            columns: [
              {
                id: "sellpriceusd2023",
                header: " 2023",
                accessorKey: "year_2023",
                accessorFn: (d) => {
                  return <div>{d?.sell_price.year_2023.sp}</div>;
                },
              },
              {
                id: "sellpriceusd2024",
                header: " 2024",
                accessorKey:"year_2023",
                accessorFn: (d) => {
                  return <div>{d?.sell_price.year_2024.sp}</div>;
                },
              },
              {
                id: "sellpriceusd2025",
                header: " 2025",
                accessorKey: "year_2025",
                accessorFn: (d) => {
                  return <div>{d?.sell_price.year_2023.sp}</div>;
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },
    ],
    []
  );

  const dtafilter = data
    .filter((a) => a.warehouse === "FAT")
    .filter((e) => e.closing_chance === "100%");

  const sumsales = dtafilter
    .filter((sale) => !isNaN(parseFloat(sale.annual_sales.year_2023.sales)))
    .map((sale) => parseFloat(sale.annual_sales.year_2023.sales))
    .reduce((previous, current) => previous + current, 0);

  console.log(sumsales);

  
  const [graphic, setgraphic]=useState(false);
  const [tableview, settableview]=useState(true);

  const handlegrafica=()=>{
    settableview(false);
    setgraphic(true);
  }
  const handletabla=()=>{
    settableview(true);
    setgraphic(false);
  }
  const update_identifiers = [
    { warehouse: 'string' },
    { id: 'string' },
    { resp: 'string' },
    { CRM: 'string' },
    { closing_chance: 'string' },
    { customer: 'string' },
    { MPG: 'string' },
    { reference: 'string' },
    { application: 'string' },
    { carry_over: 'string' },
    { sector: 'string' },
    { production_brand: 'string' },
    { name_plate: 'string' },
    { country: 'string' },
    { program: 'string' },
    { platform: 'string' },
    { annual_salesyear_2023margin: 'string' },
    { annual_salesyear_2023sales: 'string' },
    { annual_salesyear_2023sales_porcentaje: 'string' },
    { annual_salesyear_2024margin: 'string' },
    { annual_salesyear_2024sales: 'string' },
    { annual_salesyear_2024sales_porcentaje: 'string' },
    { annual_salesyear_2025margin: 'string' },
    { annual_salesyear_2025sales: 'string' },
    { annual_salesyear_2025sales_porcentaje: 'string' },
    { blended_salesyear_2023margin: 'string' },
    { blended_salesyear_2023sales: 'string' },
    { blended_salesyear_2023sales_porcentaje: 'string' },
    { blended_salesyear_2024margin: 'string' },
    { blended_salesyear_2024sales: 'string' },
    { blended_salesyear_2024sales_porcentaje: 'string' },
    { blended_salesyear_2025margin: 'string' },
    { blended_salesyear_2025sales: 'string' },
    { blended_salesyear_2025sales_porcentaje: 'string' },
    { DOM: 'string' },
    { vehicles_yearyear_2023vy: 'string' },
    { vehicles_yearyear_2024vy: 'string' },
    { vehicles_yearyear_2025vy: 'string' },
    { pieces_yearyear_2023pieces: 'string' },
    { pieces_yearyear_2024pieces: 'string' },
    { pieces_yearyear_2025pieces: 'string' },
    { BIP: 'string' },
    { Supp: 'string' },
    { I_F: 'string' },
    { sell_priceyear_2023sp: 'string' },
    { sell_priceyear_2024sp: 'string' },
    { sell_priceyear_2025sp: 'string' }
  ];


  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <Modal_Table
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <div className="">
        <div className="">
        <div className="menu-superior-graphic">
            <div className="grid-button1">
                <button className="button" onClick={handletabla}>Tabla</button>
            </div>
            <div className="grid-button2">
                <button className="button" onClick={handlegrafica}>Gráfica</button>
            </div>
          </div>

          <div style={graphic===true?{"display":"block"}:{"display":"none"}}>
            <Graphics data={data}></Graphics>
          </div>

          <div className="new_table_v2">
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableRowActions
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{ showGlobalFilter: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}    
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  handleSubmit={handleSubmit}
                  reconsultar={handleSubmit}
                />
              ]}
              // renderToolbarInternalActions={({ table, index }) => (
              //   <RENDER_TOOLBAR_INTERNAL_ACTIONS
              //     table={table}
              //     file_name={"Demobf"}
              //     // update_identifiers={update_identifiers}
              //     key={index}
              //   />
              //  )
              //  }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
