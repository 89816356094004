//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { SelectColumnFilter } from "../componentsCFDI/filters";
import TableContainer from "./TableContainerBusnes";
import SubTable from "./subTable";

import Modal_Table from "../main_components/modal/modal_Table";
//ESTILOS
import "../componentsCFDI/estilo.css";
import "./tableclientes.css";
import "../componentsCFDI/modal.css";
//IMAGENES/ICONOS

import DemoJson3 from "../dataComponets/demo3.json";
import { Style } from "@material-ui/icons";
import AWS from 'aws-sdk';

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
var style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },

    success: {
      // Applied only to the success notification item
      color: "red",
    },
  },
};


const BlemSales = () => {

  const [mensajeDescarga, setmensajeDescarga] = useState("");
  console.log(DemoJson3);

  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");


  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData(DemoJson3);
    setIsLoding(true);
    setmodalT(false);

    setmodalGeneral(false);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };




  
  
  
  


  function handleArchivoSeleccionado(evento) {
    const archivoExcel = evento.target.files[0];
    // subirArchivoA_S3(archivoExcel);
    console.log(archivoExcel)
  }
  const columns = useMemo(
    () => [

      {
        id: "warehouse",
        header: "Warehouse",
        accessorKey: "warehouse",
        // Filter: SelectColumnFilter,
      },
      {
        header: "Breakdown of Projects by Closing Chance",
        accessorKey: "breakdown",
        accessorFn: (d)=>{
          return(<div>{d.Breakdown}</div>)
        },
        size: 300
        // Filter: SelectColumnFilter,
      },
      {
        id: "AnnualSales",
        header: "Annual Sales",
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: "#9D1313",
          },
        }),
        columns: [
          {
            header: "2023",
            id:"anualsales2023",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#9D1313",
              },
            }),
            columns: [  
              {
                id: "anualsales2023sales",
                header: "Sales",
                accessorKey: "annual_sales.year_2023.sales",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2023.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
            ],
          },
          {
            id: "anualsales2024",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#9D1313",
              },
            }),
            header: "2024",
            columns: [
              {
                id: "anualsales2024sales",
                header: "Sales ",
                accessorKey: "annual_sales.year_2024.sales",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2024.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
            
             ],
          },
          {
            id: "anualsales2025",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#9D1313",
              },
            }),
            header: "2025",
            columns: [
              {
                id: "anualsales2025sales",
                header: " Sales ",
                accessorKey: "annual_sales.year_2025.sales",
                accessorFn: (d) => {
                  return <div>{d?.annual_sales.year_2025.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#9D1313",
                  },
                }),
              },
            ],
          },
        ],
      },
      {
        id: "blendedsales",
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: "#3F0E4E",
          },
        }),
        header: "Blended Sales",
        columns: [
          {
            id: "blendedsales2023",
            header: "2023",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#3F0E4E",
              },
            }),
            columns: [
              {
                id: "blendedsales2023sales",
                header: " Sales",
                accessorKey: "blended_sales.year_2023.sales",
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2023.sales}</div>;
                },
              },
              {
                id: "blendedsales2023p",
                header: " %",
                accessorKey: "blended_sales.year_2023.sales_porcentaje",
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
                accessorFn: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2023.sales_porcentaje}</div>
                  );
                },
              },
            ],
          },
          {
            id: "blendedsales2024",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#3F0E4E",
              },
            }),
            header: "2024",
            columns: [
              {
                id: "blendedsales2024sales",
                header: "Sales",
                accessorKey: "blended_sales.year_2024.sales",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2024.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "blendedsales2024p",
                header: "%",
                accessorKey: "blended_sales.year_2024.sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2024.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
            ],
          },
          {
            id: "blendedsales2025",
            header: "2025",
            muiTableHeadCellProps: () => ({
              sx: {
                backgroundColor: "#3F0E4E",
              },
            }),
            columns: [
          
              {
                id: "blendedsales2025sales",
                header: "  Sales  ",
                accessorKey: "blended_sales.year_2025.sales",
                accessorFn: (d) => {
                  return <div>{d?.blended_sales.year_2025.sales}</div>;
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
              {
                id: "blendedsales2025p",
                header: "  %  ",
                accessorKey: "blended_sales.year_2025.sales_porcentaje",
                accessorFn: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2025.sales_porcentaje}</div>
                  );
                },
                muiTableHeadCellProps: () => ({
                  sx: {
                    backgroundColor: "#3F0E4E",
                  },
                }),
              },
            ],
          },
        ],
      },
    ],
    []
  );


  const dtafilter=data.filter((a)=>a.warehouse==="FAT").filter((e)=>e.closing_chance==="100%");

  const sumsales = dtafilter
  .filter((sale) => !isNaN(parseFloat(sale.annual_sales.year_2023.sales)))
  .map((sale) => parseFloat(sale.annual_sales.year_2023.sales))
  .reduce((previous, current) => previous + current, 0);

  console.log(sumsales);





  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <Modal_Table
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <div className="">
        <div className="">

        <div>
    <input type="file" onChange={handleArchivoSeleccionado} />
  </div>        
          <div className="new_table_v2">
          <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyheader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{ showGlobalFilter: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}    
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlemSales;
