//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { Confirm } from "react-st-modal";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, Button } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import NotificationSystem from 'react-notification-system';
import dayjs from "dayjs";
//COMPONENTES Y FUNCIONES
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { formattedCurrentDate, formattedPreviousDate } from "../../main_components/date/day";
import { donwload_invoices } from "../../services/expenses/expenses";
import { base64toBlobxml, base64toBlob } from "../../main_components/donwloadfuncion/download";
import { delete_supplier_invoices } from "../../services/expenses/expenses";
import { get_supplier_invoices } from "../../services/expenses/expenses";
import { update_null_identifiers } from "../../main_components/methods_v2/auxiliar_funtion";
//CSS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/table.css";
import "../../components_cfdi/modal.css";
//IMG
import PDF from "../../imgComponents/pdf.png"
import DELETE from "../../imgComponents/borrar.png"
import XML from "../../imgComponents/xml.png"
const SUPPLIER_INVOICE = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [data, setData] = useState([]);
  const [ff, setff] = useState(dayjs(formattedCurrentDate));
  const [fi, setfi] = useState(dayjs(formattedPreviousDate));
  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");
  const [modalT, setmodalT] = useState(false);
  const [total_rows, settotal_rows] = useState("");
  const notificationSystemRef = useRef();



  const handleSubmit = async (query_filters_flag, query_filters_data) => {

    setmodalT(true);
    setIsLoading(true);
    var d;
    try {
      if (query_filters_flag) {
        console.log("entra")
        setfi(dayjs(query_filters_data.di))
        setff(dayjs(query_filters_data.df))
        d = await get_supplier_invoices(query_filters_data.di, query_filters_data.df);
        setquery_filters({
          di: query_filters_data.di,
          df: query_filters_data.df
        });
      } else {
        setquery_filters({
          di: fi.format('YYYY-MM-DD'),
          df: ff.format('YYYY-MM-DD')
        });
        d = await get_supplier_invoices(fi.format('YYYY-MM-DD'), ff.format('YYYY-MM-DD'),);
        setmodalT(false);
        setIsLoading(false);
      }
      setData(update_null_identifiers(d?.data, update_identifiers));
    } catch (err) {
      console.log(err);
      setmodalT(false);
      setData([]);
      setIsLoading(false);
    }
  };

  const handle_delete_invoice = async (id) => {
    setmodalT(true);
    try {
      await delete_supplier_invoices(id);
      setmodalT(false);
    } catch (err) {
      setmodalT(false);
    }
  };


  const handle_download_invoice = async (id, file_name, document_type) => {
    setmodalT(true);

    try {
      var data = await donwload_invoices(id, file_name, document_type);
      setmodalT(false);
      var dt = data.data.file;
      var blob;
      if (document_type === "xml") {
        blob = base64toBlobxml(dt);
        console.log("entra aqui");
      } else {
        blob = base64toBlob(dt);
      }
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      var arr = file_name?.split("/");
      downloadLink.download = arr[4] + `.${document_type}`;
      downloadLink.click();
      setmodalT(false);
    } catch (err) {
      setmodalT(false);
    }
  };


  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "Actions",
        header: "Acciones",
        enableFilters: false,
        enableEditing: false,
        enableRowOrdering: false,
        enableGrouping: false,
        enableColumnDragging: false,
        enableColumnActions: false,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        enableColumnFilter: false,
        accessorFn: (d) => {
          return (
            <div>
              <img
                alt=""
                src={PDF}
                title="Descargar PDF"
                onClick={() => handle_download_invoice(d?.id, d?.filename, "pdf")}
              ></img>
              <img
                alt=""
                src={XML}
                title="Descargar XML"
                onClick={() => handle_download_invoice(d?.id, d?.filename, "xml")}
              ></img>
              <img
                alt=""
                src={DELETE}
                title="ELiminar"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta factura?",
                    "Eliminar factura de proveedor",
                    "Si",
                    "No"
                  );

                  if (result) {
                    delete_supplier_invoices(d?.id)
                  } else {
                  }
                }}
              ></img>
            </div>
          )
        },
        size: 100,
      },

      {
        id: "invoice_date",
        header: "Fecha",
        accessorKey: "invoice_date",
      },

      {
        header: "Serie",
        accessorKey: "serie",
        id: "serie",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "folio",
        header: "Folio",
        accessorKey: "folio",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Cliente",
        accessorKey: "name_business_partner",
        id: "name_business_partner",
        muiTableBodyCellProps: {
          align: 'justify',
        },
        size: 300
      },
      {
        header: "RFC",
        accessorKey: "rfc_business_partner",
        id: "rfc_business_partner",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },

      {
        id: "subtotal",
        header: "Subtotal",
        accessorKey: "subtotal",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "discount",
        header: "Descuento",
        accessorKey: "discount",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "tax",
        header: "Impuesto",
        accessorKey: "tax",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: "total",
        header: "Total",
        accessorKey: "total",
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()?.toLocaleString?.('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Box>
        ),
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },

      {
        header: "Método de pago",
        accessorKey: "payment_method",
        id: "payment_method",
        filterVariant: 'select',
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Forma de Pago",
        accessorKey: "forma_pago",
        id: "forma_pago",
        filterVariant: 'select',
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "tipodecomprobante",
        header: "Tipo de comprobante",
        accessorKey: "tipodecomprobante",
        filterVariant: 'select',
        muiTableBodyCellProps: {
          align: 'center',
        },

      },
      {
        id: "cancel",
        header: "Estado",
        accessorKey: "cancel",
        filterVariant: 'select',
        accessorFn: (d) => {
          return d.cancel === true ? "CANCELADO" : "VIGENTE";
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: "uuid_sat",
        header: "UUID_SAT",
        accessorKey: "uuid_sat",
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        header: "Usuario",
        accessorKey: "username",
        id: "username"
      },
    ],
    []
  );

  const update_identifiers =
    [
      { invoice_date: 'string' },
      { serie: 'string' },
      { folio: 'String' },
      { name_business_partner: 'string' },
      { rfc_business_partner: 'string' },
      { subtotal: 'string' },
      { discount: 'string' },
      { tax: 'string' },
      { total: 'string' },
      { tipodecomprobante: 'string' },
      { cancel: 'string' },
      { username: 'string' },
      { payment_method: 'string' },
      { forma_pago: 'string' },
      { uuid_sat: 'string' }
    ];

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });
  }

  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  return (
    <div>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
      <MODAL_TABLE open={true} modalT={modalT}></MODAL_TABLE>
      <BREADCRUMBS
        niveles={[
          {
            label: "FACTURAS DE PROVEEDORES",
            path: null,
          },
        ]}
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
        old_version={true}
        modul="CONSULTAR FACTURAS_PROVEEDORES_V2"
        path="/consultaFacturasProvedores"
      ></BREADCRUMBS>
      <div className="new_table_v2">

        <MaterialReactTable
          columns={columns}
          data={data}
          enableGrouping
          enablePinning
          enableFacetedValues
          enableStickyHeader
          enableStickyFooter
          enableRowPinning
          enableColumnFilterModes
          enableRowSelection
          getRowId={(row) => row?.id}
          onRowSelectionChange={setRowSelection}
          enableTooltips={false}
          localization={MRT_Localization_ES}
          state={{ rowSelection, isLoading: isLoading }}
          enableColumnResizing
          enableColumnPinning
          enableColumnOrdering
          onStateChange={handleStateChange}
          enableExpandAll={false}
          initialState={{ showGlobalFilter: true, showColumnFilters: true }}
          muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
          muiSkeletonProps={{ animation: 'pulse', height: 28 }}
          rowVirtualizerInstanceRef
          rowVirtualizerOptions={{ overscan: 5 }}
          columnVirtualizerOptions={{ overscan: 2 }}
          muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
          positionToolbarAlertBanner="bottom"
          paginationDisplayMode='pages'
          rowPinningDisplayMode='sticky'
          layoutMode="grid"
          muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}

          muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
          muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
          displayColumnDefOptions={{
            'mrt-row-pin': {
              enableHiding: true,
            },

            'mrt-row-expand': {
              enableHiding: true,
            },
            'mrt-row-select': {
              enableHiding: true,
            }
          }}
          muiTableBodyRowDragHandleProps={({ table }) => ({
            onDragEnd: () => {
              const { draggingRow, hoveredRow } = table.getState();
              if (hoveredRow && draggingRow) {
                data.splice(
                  hoveredRow.index,
                  0,
                  data.splice(draggingRow.index, 1)[0],
                );
                setData([...data]);
              }
            },
          })}
          muiTableBodyRowProps={
            ({ row, table }) => {
              settotal_rows(table);
              const { density } = table.getState();
              return {
                sx: {
                  height: row.getIsPinned()
                    ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                    }px`
                    : undefined,
                },
              };
            }
          }

          renderTopToolbarCustomActions={({ }) => (
            <Box sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Fecha Inicial"
                    value={fi}
                    onChange={(newValue) => setfi(newValue)}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Fecha Inicial"
                    value={ff}
                    onChange={(newValue) => setff(newValue)}
                  />
                </LocalizationProvider>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  title='BUSCAR'
                  sx={{ height: '35px', borderRadius: "15px", marginRight: "5px", width: "50%" }}
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  <ManageSearchIcon></ManageSearchIcon>
                </Button>
              </Box>
            </Box>
          )}

          renderToolbarInternalActions={({ table, index }) => (
            <RENDER_TOOLBAR_INTERNAL_ACTIONS
              table={table}
              file_name={"Consultar Documentos Enviados"}
              update_identifiers={update_identifiers}
              settotal_rows={settotal_rows}
              query_filters={query_filters}
              key={index}
              handle_success_filter={handle_success_filter}
              share_filter={true}
              total_rows={total_rows !== "" ? total_rows : 0}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SUPPLIER_INVOICE;
