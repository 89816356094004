//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";
import Modal_Table from "../../main_components/modal/modal_Table";
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import TableContainer from "../../componentsUpdateBusnes/TableContainerBusnes";
//ESTILOS
import "../../componentsCFDI/estilo.css";
import "../../componentsCFDI/modal.css";
import "./catalogexpense.css";
//IMAGENES/ICONOS
import Spinner from "../../componentsPago/S.gif";
import Delete from "../../imgComponents/borrar.png";
import Details from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";

import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import CreateExpenseConcept from "./CreateExpenseConcept";
import EditExpenseConcept from "./EditExpenseConcept";
import { SelectColumnFilter } from "../../componentsCFDI/filters";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";

const TableExpenseConcept = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [dataroles, setdataroles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = useState("");
  const [open, setopen] = useState(false);
  var entrada = 0;

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const URL_BASE = "https://7t0suvusg7.execute-api.us-east-1.amazonaws.com";
  useEffect(() => {
    handleSubmit();
  }, []);

  function alertas(msj, status) {
    if (status == true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }
  const reconsultar = (n) => {
    if (n == true) {
      setmodalT(true);
    } else {
      setmodalT(false);
    }
  };

  //proceso de consultar el catalogo de conceptos de Gastos
  const handleSubmit = () => {
    console.log("entra a consultar todos los catalogos de gastos");
    refresh_token();

    if (entrada == 0) {
      entrada = entrada + 1;
      setmenssage("Consultando Catálogo de Gastos");
      setmodalGeneral(true);
      setmodalT(true);
    } else {
      setmodalT(true);
    }

    setopen(true);
    setData([]);

    setTimeout(() => {
      axios
        .get(`${URL_BASE}/od/expense-concepts`, {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data.exp_income_rules);
          const data = response.data.exp_income_rules;
          setData(data);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        });
    }, 2000);
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <img
              id="crearRol"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(
                  <div>
                    <CreateExpenseConcept
                      handleSubmit={handleSubmit}
                      alertas={alertas}
                      reconsultar={reconsultar}
                    ></CreateExpenseConcept>
                  </div>,
                  {
                    className: "modalTItle",
                    title: "Crear Catálogo de Conceptos de Gastos",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
              title="Crear Catálogo de Conceptos de Gastos"
              className="imgTam"
            ></img>
          </div>
        ),

        id: "Acciones",
        Cell: ({ valor, row }) => {
          function Detalles() {
            console.log("entra en detalles");
            console.log(row.original);
            sessionStorage.setItem("id_catalogo", row.original.id);
            sessionStorage.setItem(
              "nombre_catalogo",
              row.original.catalog_name
            );
            window.location = "/consult_assigned_concepts";
          }

          function DeleteSector() {
            setmodalT(true);
            refresh_token();
            setData([]);
            setopen(true);

            setTimeout(() => {
              var url = `${URL_BASE}/od/expense-concepts/${row.original.id}`;
              var axiosConfig = {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem(
                    "getAccesTokenRefresh"
                  ),
                  "Content-Type": "application/json",
                },
              };
              axios
                .delete(url, axiosConfig)
                .then((response) => {
                  console.log(response);
                  alertas("Catalogo de Conceptos de Gastos Eliminado", true);
                  handleSubmit();
                })
                .catch((err) => {
                  console.log("error", err);
                  setopen(false);
                  alertas(
                    "Error, no se pudo eliminar el Catálogo de Conceptos de Gastos",
                    false
                  );
                  handleSubmit();
                });
            }, 2000);
          }
          return (
            <div>
              <img
                src={Delete}
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar el Cátalogo de Conceptos de Gastos?",
                    "Eliminar Catálogo de Conceptos de Gastos",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteSector();
                  }
                }}
                className="centerText, imgTam"
                title="Eliminar Catálogo de Conceptos de Gastos"
              ></img>
              <img
                src={Details}
                onClick={Detalles}
                className="centerText, imgTam"
                title="Conceptos asignados"
              ></img>
              <img
                src={Edit}
                onClick={async () => {
                  const result = await CustomDialog(
                    <div>
                      <EditExpenseConcept
                        row={row}
                        handleSubmit={handleSubmit}
                        alertas={alertas}
                        reconsultar={reconsultar}
                      ></EditExpenseConcept>
                    </div>,
                    {
                      className: "modalTItle",
                      title: "Editar Catálogo de Conceptos de Gastos",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="centerText, imgTam"
                title="Editar Catálogo de Conceptos de Gastos"
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Concepto",
        accessor: "catalog_name",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Estado",
        accessor: (d) => {
          return d.enable == true ? "Habilitado" : "Deshabilitado";
        },
      },
      {
        Header: "Rol Asignado",
        accessor: "name_role",
      },
      {
        Header: "Fecha Registrada",
        accessor: "date_register",
      },
    ],
    []
  );
  return (
    <>

      <BREADCRUMBS niveles={
        [
          { label: "CONSULTAR CATÁLOGO DE CONCEPTOS DE GASTO", path: null },

        ]
      }
        new_version={true}
        modul="CATALOGO DE CONCEPTOS V2"
        path="/catalog_expense_concept_v2"
      ></BREADCRUMBS>

      <div className="TableCatalogoGastos">
        <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
        <Modal_Table
          open={true}
          message={menssage}
          modalGeneral={modalGeneral}
          modalT={modalT}
        ></Modal_Table>

        <div className="">
          <>
            <TableContainer
              paginado={"Catálogo de Gastos"}
              consultas={"Catálogos de Gastos Consultados"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default TableExpenseConcept;
