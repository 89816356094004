//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import { get_catalogs } from "../../services/catalogs/catalogs";
import { delete_catalogs } from "../../services/catalogs/catalogs";
import Methods from "./methods/methods";
import Modal_Table from "../../main_components/modal/modal_Table";
//ESTILOS
import "./general_catalogs.css";
import { upperCase } from "lodash";
import CREATE from "../../imgComponents/create.png";
import DELETE from "../../imgComponents/borrar.png";
import SAVE from "../../imgComponents/save.png";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS from "./tools/render_row_action_menuItems";

const TableCatalogs = (props) => {

  const notificationSystemRef = useRef();
  const [value, setValue] = React.useState("");
  const [valuetwo, setValuetwo] = React.useState("");
  const [valuethree, setValuethree] = React.useState("");
  const [id_value_data, setid_value_data] = React.useState("");
  const [data, setData] = React.useState([]);

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [open, setopen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [total_rows, settotal_rows] = useState("");


  const reconsultar = (n) => {
    if (n == true) {
      setmodalT(true);
    } else {
      setmodalT(false);
    }
  }


  function alerts(msj, status) {

    if (status == true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true} ing={true}></AlertResponse>,
        level: 'success',
        position: 'br',
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false} ing={true}></AlertResponse>,
        level: 'error',
        position: 'br',
        autoDismiss: 60,
      });
    }
  }

  useEffect(() => {
    handledata();
  }, []);

  const handledata = async () => {
    setData([]);
    try {
      setopen(true);
      setmodalT(true);
      var res = await get_catalogs(props.tablename);
      res.sort((a, b) => b.id - a.id);
      setData(res);
      setopen(false);
      setmodalT(false);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      setData([]);
      setopen(false);
      setmodalT(false);
    }
  };


  const handle_delete_data = async (id_value) => {
    setmodalT(true);
    try {
      var res = await delete_catalogs(props.tablename, id_value);
      alerts("Successfully Removed", true);
      handlereconsult(true);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      alerts("Error Deleting", false);
      setmodalT(false);
    }
  };



  const handlereconsult = (valid) => {
    if (valid === true) {
      setData([]);
      handledata();
    } else {

    }
  }

  const columns = useMemo(
    () => [
      // {
      //   header: () => (
      //     <div>
      //       {
      //         props.tablename === "EXR" ? <>Actions</> : <img
      //           src={CREATE}
      //           title="Create"
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <Methods response={props?.response} response2={props?.response2} response3={props?.response3} value={value} valuetwo={valuetwo} valuethree={valuethree} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"post"} reconsultar={reconsultar} alerts={alerts}></Methods>,
      //            
      //               {
      //                 className: "",
      //                 title: "CREATE",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             handlereconsult(result);
      //           }}

      //         ></img>
      //       }

      //     </div>
      //   ),
      //   id: "expander",
      //   Cell: ({ row }) => {
      //     return (
      //       <div>

      //         <img
      //           src={SAVE}
      //           title="Update"
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <Methods id_data={row.original.id} response={props?.response} response2={props?.response2} response3={props?.response3} value={row.original[`${props.response}`]} valuetwo={row.original[`${props.response2}`]} valuethree={row.original[`${props.response3}`]} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"put"} reconsultar={reconsultar} alerts={alerts}></Methods>,
      //               {
      //                 className: "",
      //                 title: "UPDATE",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             handlereconsult(result);
      //           }}

      //         ></img>

      //         {
      //           props.tablename === "EXR" ? <></> :
      //             <img
      //               src={DELETE}
      //               title="Delete"
      //               onClick={
      //                 async () => {
      //                   const result = await Confirm(
      //                     "Are you sure to delete the attribute?",
      //                     "DELETE",
      //                     "YES",
      //                     "No"
      //                   );
      //                   if (result) {
      //                     handle_delete_data(row.original.id);
      //                   } else {
      //                   }
      //                 }
      //               }
      //             ></img>
      //         }

      //       </div>
      //     );
      //   },
      // },

      {
        header: `${upperCase(props.response)}`,
        accessorKey: `${props.response}`,
      },
    ],
    [data]
  );


  const columns2 = useMemo(
    () => [
      // {
      //   header: () => (
      //     <div>
      //       <img
      //         src={CREATE}
      //         title="Create"
      //         onClick={async () => {
      //           const result = await CustomDialog(
      //             <Methods response={props?.response} response2={props?.response2} response3={props?.response3} value={value} valuetwo={valuetwo} valuethree={valuethree} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"post"} alerts={alerts} reconsultar={reconsultar}></Methods>,
      //             {
      //               className: "",
      //               title: "CREATE",
      //               showCloseIcon: true,
      //               isCanClose: false,
      //             }
      //           );
      //           handlereconsult(result);
      //         }}

      //       ></img>
      //     </div>
      //   ),
      //   id: "expander",

      //   Cell: ({ row }) => {

      //     return (
      //       <div>
      //         <img
      //           src={SAVE}
      //           title="Update"
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <Methods id_data={row.original.id} response={props?.response} response2={props?.response2} response3={props?.response3} value={row.original[`${props.response}`]} valuetwo={row.original[`${props.response2}`]} valuethree={row.original[`${props.response3}`]} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"put"} alerts={alerts} reconsultar={reconsultar}></Methods>,
      //               {
      //                 className: "",
      //                 title: "UPDATE",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             handlereconsult(result);
      //           }}

      //         ></img>
      //         <img
      //           src={DELETE}
      //           title="Delete"
      //           onClick={
      //             async () => {
      //               const result = await Confirm(
      //                 "Are you sure to delete the attribute?",
      //                 "DELETE",
      //                 "YES",
      //                 "No"
      //               );
      //               if (result) {
      //                 handle_delete_data(row.original.id);
      //               } else {
      //               }
      //             }
      //           }
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "closingchange",
        header: `${upperCase(props.response)}`,
        accessorKey: "closing_chances",
        accessorFn: (d) => {
          return props.tablename === "Closing_Chances" ? `${d.closing_chances}%` : `${d.closing_chances}`
        }
      },
      {
        header: `${upperCase(props.response2)}`,
        accessorKey: `${props.response2}`
      },
    ],
    [data]
  );

  const columns3 = useMemo(
    () => [
      // {

      //   header: () => (
      //     <div>
      //       <img
      //         src={CREATE}
      //         tile="Create"
      //         onClick={async () => {
      //           const result = await CustomDialog(
      //             <Methods response={props?.response} response2={props?.response2} response3={props?.response3} value={value} valuetwo={valuetwo} valuethree={valuethree} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"post"} alerts={alerts} reconsultar={reconsultar}></Methods>,
      //             {
      //               className: "",
      //               title: "CREATE",
      //               showCloseIcon: true,
      //               isCanClose: false,
      //             }
      //           );
      //           handlereconsult(result);
      //         }}

      //       ></img>
      //     </div>
      //   ),
      //   id: "expander",
      //   Cell: ({ row }) => {
      //     return (
      //       <div>
      //         <img
      //           src={SAVE}
      //           onClick={async () => {
      //             const result = await CustomDialog(
      //               <Methods id_data={row.original.id} response={props?.response} response2={props?.response2} response3={props?.response3} value={row.original[`${props.response}`]} valuetwo={row.original[`${props.response2}`]} valuethree={row.original[`${props.response3}`]} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"put"} alerts={alerts} reconsultar={reconsultar}></Methods>,
      //               {
      //                 className: "",
      //                 title: "UPDATE",
      //                 showCloseIcon: true,
      //                 isCanClose: false,
      //               }
      //             );
      //             handlereconsult(result);
      //           }}

      //         ></img>
      //         <img
      //           src={DELETE}
      //           title="Delete"
      //           onClick={
      //             async () => {
      //               const result = await Confirm(
      //                 "Are you sure to delete the attribute?",
      //                 "DELETE",
      //                 "YES",
      //                 "No"
      //               );
      //               if (result) {
      //                 handle_delete_data(row.original.id);
      //               } else {
      //               }
      //             }
      //           }
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        header: `${upperCase(props.response)}`,
        accessorKey: `${props.response}`,
      },
      {
        header: `${upperCase(props.response2)}`,
        accessorKey: `${props.response2}`,
      },

      {
        header: `${upperCase(props.response3)}`,
        accessorKey: `${props.response3}`,
      },
    ],
    [data]
  );

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };

  return (
    <div className="table-catalogs">
      <Modal_Table
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>

      {/* <TableContainer
        exportEx={true}
        columns={
          props.columdata === 3
            ? columns3
            : props.columdata === 2
              ? columns2
              : columns
        }
        data={data}
        anualSale={true}
        Gmodal={open}
        style={{ overflowx: 10 }}
        renderRowSubComponent={(row) => {
          return <div style={{ padding: "20px" }}></div>;
        }}
      /> */}
        <div className="new_table_v2">
          <MaterialReactTable
              columns={
                props.columdata === 3
                  ? columns3
                  : props.columdata === 2
                    ? columns2
                    : columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyheader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              enableColumnDragging={false}
              initialState={{ showGlobalFilter: true, showColumnFilters: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                        title="Upload File"
                        src={CREATE}
                        style={{cursor:"pointer"}}
                        className=""
                        onClick={async () => {
                          const result = await CustomDialog(
                            <Methods 
                              response={props?.response} 
                              response2={props?.response2} 
                              response3={props?.response3} 
                              value={value} 
                              valuetwo={valuetwo} 
                              valuethree={valuethree} 
                              tablename={props?.tablename} 
                              columdata={props?.columdata} 
                              visibilitydate={props?.visibilitydate}
                              method={"post"} 
                              reconsultar={reconsultar} 
                              alerts={alerts}
                              ></Methods>,
                            {
                              className: "custom-modal-2",
                              title: "Upload File",
                              showCloseIcon: true,
                              isCanClose: false,
                            }
                          );
                          handlereconsult(result);
                        }}
                      ></img>
                    </div>
                  ),
                  size: 80,
                  enableHiding: true
                },
                'mrt-row-actions': {
                  enableHiding: true
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }


                
              renderRowActionMenuItems={({ row, closeMenu }) => [
                <RENDER_ROW_ACTION_MENU_ITEMS
                  closeMenu={closeMenu}
                  setmodalT={setmodalT}
                  setmenssage={setmenssage}
                  setmodalGeneral={setmodalGeneral}
                  row={row}
                  // handleSubmit={handleSubmit}
                  id_data={row.original.id} 
                  response={props?.response} 
                  response2={props?.response2} 
                  response3={props?.response3} 
                  value={row.original[`${props.response}`]} 
                  valuetwo={row.original[`${props.response2}`]} 
                  valuethree={row.original[`${props.response3}`]} 
                  tablename={props?.tablename} 
                  columdata={props?.columdata} 
                  visibilitydate={props?.visibilitydate} 
                  method={"put"} 
                  alerts={alerts}
                  reconsultar={reconsultar}
                  handlereconsult={handlereconsult}
                />
              ]}

              renderToolbarInternalActions={({ table, index }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"CATALOGS"}
                  // update_identifiers={update_identifiers}
                  key={index}
                />
               )
              }
            />
    </div>
    </div>
  );
};

export default TableCatalogs;
