import React, { useState } from 'react';
import './file_uploader.css'; // Importar estilos CSS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { load_documents } from '../../services/portal/portal';
import MODAL_FORECAST from '../../main_components/modal/modal_forecast';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const FileUploader = () => {
    const [progress, setProgress] = useState(0);
    const [progress_docts, setprogress_docts] = useState(0);
    const [modalT, setmodalT] = useState(false);


    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFiles_limit, setuploadedFiles_limit] = useState([]);
    const [uploadedFiles_fail, setuploadedFiles_fail] = useState([]);
    const [upload_type, setupload_type] = useState("SUBIR POR DOCUMENTO");

    console.log(uploadedFiles_fail)
    const [uploadedFiles_success, setuploadedFiles_success] = useState([]);

    const [selectedPdf, setSelectedPdf] = useState(null); // Nuevo estado para el PDF seleccionado
    const [totalFileSize, setTotalFileSize] = useState(0); // Tamaño total de los archivos subidos
    const MAX_TOTAL_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
    console.log(uploadedFiles)
    console.log(totalFileSize)
    console.log(MAX_TOTAL_FILE_SIZE)

    let fileCounter = 0; // Inicializa un contador para los archivos

    const handleFileChange = (event) => {
        const files = event.target.files;
        uploadFiles(files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer.files;
        uploadFiles(files);
    };
    const uploadFiles = (files) => {
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (['image/jpeg', 'image/png', 'application/pdf', 'image/tiff'].includes(file.type)) {
                    const fileName = file.name;

                    console.log(fileName);
                    const fileSizeLimit = 4.5 * 1024 * 1024; // 5 MB

                    if (file.size > fileSizeLimit) {
                        // Si el archivo excede el tamaño límite, lo procesa con la función para archivos no permitidos
                        uploadFile_limit(file, fileName);
                    } else {
                        // Si el archivo no excede el tamaño límite, lo procesa con la función estándar para archivos permitidos
                        uploadFile(file, fileName);
                        setTotalFileSize(prevSize => prevSize + file.size); // Actualiza el tamaño total solo para archivos permitidos
                    }
                }
            }
        }
    };


    const uploadFile = (file, fileName) => {
        const fileSize = file.size;
        const chunkSize = 1024 * 1024; // 1MB
        const totalChunks = Math.ceil(fileSize / chunkSize);
        let uploadedChunks = 0;
        const uniqueKey = `${fileName}-${Date.now()}-${fileCounter++}`; // Generar un identificador único para cada carga

        // Leer el archivo y convertirlo a Base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64String = reader.result;

            const uploadNextChunk = () => {
                const start = uploadedChunks * chunkSize;
                const end = Math.min(start + chunkSize, fileSize);

                setTimeout(() => {
                    const chunkProgress = Math.ceil(((uploadedChunks + 1) / totalChunks) * 100);
                    setProgress(chunkProgress);

                    uploadedChunks++;
                    if (uploadedChunks < totalChunks) {
                        uploadNextChunk();
                    } else {
                        // Agrega el archivo codificado en base64 al estado
                        setUploadedFiles((prevFiles) => [...prevFiles, { fileName, fileSize, key: uniqueKey, fileUrl: URL.createObjectURL(file), base64: base64String }]);
                        setProgress(0); // Reiniciar el progreso después de la carga completa
                    }
                }, 1000); // Simular tiempo de carga
            };

            uploadNextChunk();
        };

        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    };


    // const uploadFile = (file, fileName) => {
    //     const fileSize = file.size;
    //     const chunkSize = 1024 * 1024; // 1MB
    //     const totalChunks = Math.ceil(fileSize / chunkSize);
    //     let uploadedChunks = 0;
    //     const uniqueKey = `${fileName}-${Date.now()}-${fileCounter++}`; // Generar un identificador único para cada carga

    //     const uploadNextChunk = () => {
    //         const start = uploadedChunks * chunkSize;
    //         const end = Math.min(start + chunkSize, fileSize);

    //         setTimeout(() => {
    //             const chunkProgress = Math.ceil(((uploadedChunks + 1) / totalChunks) * 100);
    //             setProgress(chunkProgress);
    //             const fileUrl = URL.createObjectURL(file);

    //             uploadedChunks++;
    //             if (uploadedChunks < totalChunks) {
    //                 uploadNextChunk();
    //             } else {
    //                 setUploadedFiles((prevFiles) => [...prevFiles, { fileName, fileSize, key: uniqueKey, fileUrl }]);
    //                 setProgress(0); // Reiniciar el progreso después de la carga completa
    //             }
    //         }, 1000); // Simular tiempo de carga
    //     };

    //     uploadNextChunk();
    // };

    const uploadFile_limit = (file, fileName) => {
        const fileSize = file.size;
        const chunkSize = 1024 * 1024; // 1MB
        const totalChunks = Math.ceil(fileSize / chunkSize);
        let uploadedChunks = 0;
        const uniqueKey = `${fileName}-${Date.now()}-${fileCounter++}`; // Generar un identificador único para cada carga

        const uploadNextChunk = () => {
            const start = uploadedChunks * chunkSize;
            const end = Math.min(start + chunkSize, fileSize);

            setTimeout(() => {
                const chunkProgress = Math.ceil(((uploadedChunks + 1) / totalChunks) * 100);
                setProgress(chunkProgress);
                const fileUrl = URL.createObjectURL(file);

                uploadedChunks++;
                if (uploadedChunks < totalChunks) {
                    uploadNextChunk();
                } else {
                    setuploadedFiles_limit((prevFiles) => [...prevFiles, { fileName, fileSize, key: uniqueKey, fileUrl }]);
                    setProgress(0); // Reiniciar el progreso después de la carga completa
                }
            }, 1000); // Simular tiempo de carga
        };

        uploadNextChunk();
    };

    const handle_load_document = async () => {
        console.log(uploadedFiles);
        const successfulUploads = [];
        const failedUploads = [];
        setmodalT(true);

        for (let i = 0; i < uploadedFiles.length; i++) {
            const file = uploadedFiles[i];
            setprogress_docts((i / uploadedFiles.length) * 100);
            const documentPayload = {
                "documents": [
                    {
                        "filename": file.fileName,
                        "content": file.base64.replace("data:application/pdf;base64,", "")
                    }
                ]
            };

            try {
                console.log(documentPayload);
                await load_documents(documentPayload);
                successfulUploads.push(file.fileName);
            } catch (error) {
                console.error("Error uploading document:", file.fileName, error);
                failedUploads.push({ "fileName": file.fileName, "fileSize": file.fileSize, "key": file.key, "fileUrl": file.fileUrl, "base64": file.base64, "error": error?.response?.data?.errorMessage });
            }
        }

        setmodalT(false);

        console.log("Successful uploads:", successfulUploads);
        console.log("Failed uploads:", failedUploads);
        setUploadedFiles([])

        setuploadedFiles_fail(failedUploads);
        setuploadedFiles_success(successfulUploads);
    };



    const handleDelete = (key, size) => {
        setUploadedFiles((prevFiles) => {
            const deletedFile = prevFiles.find((file) => file.key === key);
            const newFiles = prevFiles.filter((file) => file.key !== key);
            if (selectedPdf && selectedPdf.key === key) {
                setSelectedPdf(null);
            }
            setTotalFileSize((prevTotalFileSize) => prevTotalFileSize - size);
            console.log(size)

            return newFiles;
        });
    };

    const handleDelete_error = (key, size) => {
        setuploadedFiles_fail((prevFiles) => {
            const deletedFile = prevFiles.find((file) => file.key === key);
            const newFiles = prevFiles.filter((file) => file.key !== key);
            if (selectedPdf && selectedPdf.key === key) {
                setSelectedPdf(null);
            }
            setTotalFileSize((prevTotalFileSize) => prevTotalFileSize - size);
            console.log(size)

            return newFiles;
        });
    };
    const handleDelete_limit = (key, size) => {
        setuploadedFiles_limit((prevFiles) => {
            const deletedFile = prevFiles.find((file) => file.key === key);
            const newFiles = prevFiles.filter((file) => file.key !== key);
            if (selectedPdf && selectedPdf.key === key) {
                setSelectedPdf(null);
            }
            setTotalFileSize((prevTotalFileSize) => prevTotalFileSize - size);
            console.log(size)
            return newFiles;
        });
    };

    const formatFileSize = (bytes) => {
        if (bytes < 1024) return `${bytes} B`;
        if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`;
        return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    };

    const handlePreview = (file) => {
        setSelectedPdf(file); // Establecer el archivo PDF seleccionado para la previsualización
    };

    const getFileClass = (fileSize) => {
        return fileSize > MAX_TOTAL_FILE_SIZE ? 'file-size-exceeded' : '';
    };



    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });



    const handleDirectoryChange = async () => {
        if (!window.showDirectoryPicker) {
            return;
        }

        try {
            const directoryHandle = await window.showDirectoryPicker();
            const uploadedFiles = [];
            const exceededSizeFiles = [];

            for await (const entry of directoryHandle.values()) {
                if (entry.kind === 'file') {
                    const file = await entry.getFile();

                    if (['image/jpeg', 'image/png', 'application/pdf', 'image/tiff'].includes(file.type)) {
                        const fileName = file.name;
                        const fileSize = file.size;
                        const fileSizeLimit = 4.5 * 1024 * 1024; // 5 MB
                        const chunkSize = 1024 * 1024; // 1MB



                        if (fileSize > fileSizeLimit) {
                            // Si el archivo excede el tamaño límite, lo agregamos al arreglo de archivos que excedieron el límite
                            exceededSizeFiles.push({
                                fileName,
                                fileSize
                            });

                            uploadFile_limit(file, fileName);

                            continue; // Pasamos al siguiente archivo
                        }

                        const totalChunks = Math.ceil(fileSize / chunkSize);
                        let uploadedChunks = 0;
                        const uniqueKey = `${fileName}-${Date.now()}-${fileCounter++}`; // Generar un identificador único para cada carga

                        const reader = new FileReader();
                        reader.readAsDataURL(file);

                        reader.onload = () => {
                            const base64String = reader.result;

                            const uploadNextChunk = () => {
                                const start = uploadedChunks * chunkSize;
                                const end = Math.min(start + chunkSize, fileSize);

                                setTimeout(() => {
                                    const chunkProgress = Math.ceil(((uploadedChunks + 1) / totalChunks) * 100);
                                    setProgress(chunkProgress);

                                    uploadedChunks++;
                                    if (uploadedChunks < totalChunks) {
                                        uploadNextChunk();
                                    } else {
                                        uploadedFiles.push({
                                            fileName,
                                            fileSize,
                                            key: uniqueKey,
                                            fileUrl: URL.createObjectURL(file),
                                            base64: base64String.split(',')[1] // Remove the data URI prefix
                                        });
                                        setUploadedFiles(uploadedFiles);
                                        setProgress(0); // Reiniciar el progreso después de la carga completa
                                    }
                                }, 1000); // Simular tiempo de carga
                            };

                            uploadNextChunk();
                        };

                        reader.onerror = (error) => {
                            console.log('Error: ', error);
                        };
                    }
                }
            }


            // Puedes manejar los archivos que excedieron el límite como lo desees
            if (exceededSizeFiles.length > 0) {
                console.log('Archivos que exceden el límite de tamaño:', exceededSizeFiles);
                // Aquí puedes manejar estos archivos, por ejemplo, mostrar un mensaje al usuario
            }

        } catch (error) {
            console.error(error);
        }
    };


    return (

        <div className='container-documents-pdf'
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}>
            <MODAL_FORECAST modalGeneral={modalT} modalT={modalT} message={"subiendo archivos"} progress={progress_docts}></MODAL_FORECAST>

            <div className="wrapper">
                <header>SUBIR PEDIMENTOS</header>
                <form onClick={() => document.querySelector('.file-input').click()} style={{ display: upload_type === "SUBIR POR DOCUMENTO" ? "" : "none" }}>
                    <input className="file-input" type="file" name="file" onChange={handleFileChange} hidden multiple />
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                    <p>SELECCIONAR ARCHIVOS</p>
                </form>

                <form className="" onClick={handleDirectoryChange} style={{ display: upload_type === "SUBIR POR CARPETA" ? "" : "none" }}>
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                    <p>SELECCIONAR CARPETA</p>
                </form>

                <section className="progress-area">
                    {progress > 0 && (
                        <li className="row">
                            <FontAwesomeIcon icon={faFileAlt} />
                            <div className="content">
                                <div className="details">
                                    <span className="name">Uploading • {progress}%</span>
                                </div>
                                <div className="progress-bar">
                                    <div className="progress" style={{ width: `${progress}%` }}></div>
                                </div>
                            </div>
                        </li>
                    )}
                </section>

                <section className="uploaded-area-pedimentos">
                    <ul>
                        {uploadedFiles_fail.map(file => (
                            <li key={file.key} className={`row3 ${getFileClass(file.fileSize)}`}>
                                <div className="content upload">
                                    <PictureAsPdfIcon onClick={() => handlePreview(file)} />
                                    <div className="details">
                                        <span className="name">{`${file.fileName}`}</span>
                                        <span className="size">{file.error}</span>

                                    </div>
                                </div>
                                <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDelete_error(file.key, file.fileSize)}></DeleteIcon>
                            </li>
                        ))}
                    </ul>
                    <ul>
                        {uploadedFiles_limit.map(file => (
                            <li key={file.key} className={`row2 ${getFileClass(file.fileSize)}`}>
                                <div className="content upload">
                                    <PictureAsPdfIcon onClick={() => handlePreview(file)} />
                                    <div className="details">
                                        <span className="name">{`${file.fileName}`}</span>
                                        <span className="size">{"Este documento excede el peso permitido:" + formatFileSize(file.fileSize)}</span>
                                    </div>
                                </div>
                                <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDelete_limit(file.key, file.fileSize)}></DeleteIcon>
                            </li>
                        ))}
                    </ul>
                    <ul>
                        {uploadedFiles.map(file => (
                            <li key={file.key} className={`row ${getFileClass(file.fileSize)}`}>
                                <div className="content upload">
                                    <PictureAsPdfIcon onClick={() => handlePreview(file)} />
                                    <div className="details">
                                        <span className="name">{`${file.fileName}`}</span>
                                        <span className="size">{formatFileSize(file.fileSize)}</span>
                                    </div>
                                </div>
                                <DeleteIcon style={{ cursor: "pointer" }} onClick={() => handleDelete(file.key, file.fileSize)}></DeleteIcon>
                            </li>
                        ))}
                    </ul>

                </section>
                <br></br>
                <TextField
                    id="typedoct"
                    select
                    label="POR DOCUMENTO / POR CARPETA"
                    value={upload_type}
                    sx={{ m: 1, width: "80%" }}
                    onChange={(e) => setupload_type(e.target.value)}
                    size="small"
                    name="businessPartnerType"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        native: true,
                    }}
                >
                    {[{ value: "SUBIR POR DOCUMENTO" }, { value: "SUBIR POR CARPETA" }].map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.value}
                        </option>
                    ))}
                </TextField>
                {/* <button onClick={() => handle_load_document()}>Subir documentos</button> */}

                <Button
                    variant="contained"
                    sx={{ m: 1.5, width: "80%" }}
                    className=""
                    onClick={() => handle_load_document()}
                >
                    SUBIR DOCUMENTOS
                </Button>
            </div>

            <div className='container-pdf'>
                {
                    selectedPdf === null
                        ?
                        (
                            <div className="pdf-preview">
                                <iframe src={"https://invopedimentos.s3.amazonaws.com/petitios.pdf"} width="100%" />
                            </div>
                        )
                        :
                        (
                            <div className="pdf-preview">
                                <iframe src={selectedPdf.fileUrl} width="100%" />
                            </div>
                        )}
            </div>

        </div>
    );
};

export default FileUploader;
