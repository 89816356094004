//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import CREATE_UPDATE from "./tools/create_expense";
import SubTable from "./detail_expense/datail";
import UPDATE_EXPENSE from "./tools/update_expense";
import Modal_Table from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
import { base64toBlob } from "../../main_components/donwloadfuncion/download";
import { download_expense,delete_expense } from "../../services/expenses/expenses";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
//ESTILOS
import "../../componentsCFDI/estilo.css";
import "../expense.css";
import "../../componentsCFDI/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import PDF from "../../imgComponents/pdf.png";
import { date_current } from "../../main_components/date/date";
import { previous_date } from "../../main_components/date/date";
import { get_expense } from "../../services/expenses/expenses";

const EXPENSES = (props) => {
  const [data, setData] = useState([]);
  const [menssage, setMenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(false);
  const [ff, setff] = useState(date_current);
  const [fi, setfi] = useState(previous_date);
  const notificationSystemRef = useRef();

  const handleSubmit = async (menssageAlert) => {
    try {
      setmodalT(true);
      setMenssage("Consultando gastos...");
      console.log(props.expense)
      var dt = await get_expense(fi, ff, props.expense);
      setData(dt?.data?.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0));
      setmodalT(false);
      setmodalGeneral(false);
      setMenssage("");
      if (menssageAlert === undefined) {
        console.log("sin alerta");
      } else {
        notificationSystemRef.current.addNotification({
          message: (<AlertResponse msj={menssageAlert} view={true}></AlertResponse>),
          level: "success",
          position: "br",
          autoDismiss: 10,
        });
      }
    } catch (err) {
      console.log("error", err);
      setData([]);
      setmodalT(false);
      setMenssage("");
    }
  };

  const handle_download =async(id,file_name)=>{
    setmodalT(true);
    try {
      var dta= await download_expense(id)
      const data = dta.data;
    var blob = base64toBlob(data.file);
    const blobUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    var arr = file_name.split("/");
    downloadLink.download = arr[4] + ".pdf";
    downloadLink.click();
    setmodalT(false);
    } catch (err) {
      setmodalT(true);
    }
  }
  
  const handle_delete_expense = async (id)=>{
    setmodalT(true);
    try {
      await delete_expense(id);
      setmodalT(false);
      handleSubmit("Gasto eliminado correctamente");
    } catch (err) {
      setmodalT(false);
    }
  

  }

  const handleReconsulta = (reconsultar, a) => {
    if (reconsultar === true) {
      handleSubmit(a);
    } else {
      console.log("no se reconsulto");
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              title="Crear Gasto"
              src={CREATE}
              alt="Crear Gasto"
              onClick={async () => {
                const result = await CustomDialog(<CREATE_UPDATE
                  codCocept={""}
                  codDescripcion={""}
                  codDate={""}
                  codImporte={0}
                  codAccount={"9090909090909"}
                  codIdPayment={""}
                  id_expense={""}
                  codidConcept={""}
                  method={"CREATE"}
                  foreing={props?.foreing}
                  origin={props?.origin}
                ></CREATE_UPDATE>, {
                  className: "custom-modal",
                  title: "Crear Gasto",
                  showCloseIcon: true,
                  isCanClose: false,
                });
                // eslint-disable-next-line no-lone-blocks
                {
                  handleReconsulta(result, "Gasto creado correctamente");
                }
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_expense_subtable", row.original.id);
          }

          function Denegado() {
            alert("No tienes el rol necesario para ver los detalles del gasto");
          }

          if (
            sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
            "ROLE_EXPENSE_ADMINISTRATIVOS"
          ) {
            return (
              <a
                title="Consultar impuestos del concepto"
                {...row.getToggleRowExpandedProps()}
              >
                {row?.isExpanded ? (
                  <span title="Consultar detalles del gasto">🔼</span>
                ) : (
                  <span
                    title="Consultar detalles del gasto"
                    onClick={guardardato}
                  >
                    🔽
                  </span>
                )}
              </a>
            );
          } else {
            return (
              <span title="Consultar detalles del gasto" onClick={Denegado}>
                🔽
              </span>
            );
          }
        },
      },

      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
         
          return (
            <div>
              <img
                title="Descargar pdf"
                src={PDF}
                alt=""
                onClick={() => handle_download(row.original.id,row.original.file_name)}
                className=""
              ></img>

              <img
                title="Editar Gasto"
                src={SAVE}
                alt=""
                onClick={async () => {
                  if (row.original.modifiable !== "ADSA") {
                    const result = await CustomDialog(
                      <UPDATE_EXPENSE
                        codCocept={row.original.concept}
                        codDescripcion={row.original.description}
                        codDate={row.original.date}
                        codImporte={row.original.ammount}
                        codAccount={row.original.id_account}
                        codIdPayment={row.original.paiement_account}
                        id_expense={row.original.id}
                        codidConcept={row.original.id_account}
                        foreing={props?.foreing}
                        method={"UPDATE"}
                        id={row.original.id}
                      ></UPDATE_EXPENSE>,
                      {
                        className: "custom-modal",
                        title: "Editar Gasto",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    // eslint-disable-next-line no-lone-blocks
                    {
                      handleReconsulta(
                        result,
                        "Gasto actualizado correctamente"
                      );
                    }
                  } else {
                    alert("Este gasto ya no se puede modificar.");
                  }
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Gasto"
                src={DELETE}
                alt=""
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Gasto?",
                    "Eliminar Gasto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_expense(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "No.",
        accessor: "spend_folio_number",
      },
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      },
      {
        Header: "Editable",
        accessor: (d) => {
          return d.modifiable === true ? "✅" : "❌";
        },
      },
      {
        Header: "Cuenta",
        accessor: "account",
      },
    ],
    []
  );

  return (
    <div className="">
      <Modal_Table
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <BREADCRUMBS
        niveles={[
          {
            label:
              props.expense === "EXTRANJERO"
                ? "GASTOS DEL " + props.expense
                : "GASTOS " + props.expense,
            path: null,
          },
        ]}
        new_version={true}
        modul={"CONSULTAR "+props.expense === "EXTRANJERO"
          ? "GASTOS DEL " + props.expense 
          : "GASTOS " + props.expense}
        path={props.expense === "EXTRANJERO"
          ? "/FOREING"
          : "/expenses_v2" }
      ></BREADCRUMBS>
  
      <br></br>

      <div class="containerExpense">
        <div class="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div class="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div class="expen-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={() => {
              handleSubmit("");
            }}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="ExpenseNoDeducible">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} row={row} foreign={props.foreing} />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EXPENSES;
