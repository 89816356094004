import { Icon } from "@material-ui/core";
import { AccountBalance, ArrowDropDown, ArrowDropUp, Assessment, AttachMoney, BrightnessLow, CardGiftcard, Description, FavoriteBorderTwoTone, FindInPage, HowToReg, InsertDriveFile, Loyalty, MarkunreadMailbox, MenuOutlined, MessageOutlined, NoteAdd, NotificationsActive, Payment, PersonAdd, Policy, Restore, Security} from "@material-ui/icons";
import { Article, Badge, Calculate, ContentPasteGo, CurrencyExchange, DocumentScanner, FactCheck, FileOpen, ForkLeft, Grading, ManageAccounts, PersonSearch, Plagiarism, PointOfSale, PriceChange, RequestQuote, Sell, ShoppingCartCheckout, SnippetFolder, Task, TravelExplore } from "@mui/icons-material";

   export const iconoBanco = (size = "small") => {
      return <AccountBalance fontSize={size}/>;
     }
    export const iconoCporte = (size = "small") => {
      return <Article fontSize={size}/>;
     }
     
     export const iconoCompras = (size = "small") => {
      return <Sell fontSize={size}/>;
     }
     
     export const iconoFacturas  = (size = "small") => {
      return <Description fontSize={size}/>;
     }
     
     export const iconoFinanzas  = (size = "small") => {
      return <AttachMoney fontSize={size}/>;
     } 
     
     export const iconoAddendas = (size = "samll") => {
        return <InsertDriveFile fontSize = {size}/>;
     }
     export const iconoUnilever = (size = "small") => {
      return <Plagiarism fontSize={size}/>;
     }
     
     export const iconoReportes = (size = "small") => {
      return <Assessment fontSize={size}/>;
     }
     
     export const iconoSnegocios = (size = "small") => {
      return <Badge fontSize={size}/>;
     }

     export const desplegado = (param_open) => {
        return <Icon style={{ right: "0%"}}> { param_open ? <ArrowDropUp fontSize="small"/> : <ArrowDropDown fontSize="small"/>} </Icon>
       }
       
   
        
    export const iconoMenu = () => {
        return (<MenuOutlined />)
    }

    export const iconoSecurity = () => {
        return (<Security/> )
    }

    export const iconoFavorite = () => {
        return ( <FavoriteBorderTwoTone/>);
    }

    export const iconoNotify = () => {
        return (<NotificationsActive/>);
    }
    
    export const iconoMessage = () => {
        return (<MessageOutlined/>);
    }
                                      
    export const iconoBrightnessLow = () => {
        return (<BrightnessLow color="white" fontSize="small"/>)
    }
   
   export const desplegar = (param_open) => {
        return (
          <Icon style={{ position: "absolute", right: "0",
          top: "30%",
          transform: "translateY(-50%)",
         }}>
            {" "}
            {param_open ? (
              <ArrowDropUp style={{ fontSize:"1.0em", fontWeight: param_open ? "bold" : "normal",
              color: param_open ? "#FFFFFF" : "#F4EEF5"}} />
            ) : (
              <ArrowDropDown style={{ fontSize:"1.0em", fontWeight: param_open ? "bold" : "normal",
              color: param_open ? "#FFFFFF" : "#F4EEF5"}}/>
            )}{" "}
          </Icon>
        );
      };
