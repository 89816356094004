//PAQUETERIAS
import { useEffect, useState, useMemo, useRef, Fragment } from "react";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import dayjs from 'dayjs';

//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
//IMAGENES/ICONOS
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import { Box, Button } from "@mui/material";
import { calculate_total } from "../../main_components/methods_v2/auxiliary_functions";
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import { get_forecast } from "../../services/portal/portal";
import { date_current, previous_date } from "../../main_components/date/date";
import SelectableHeaderCheckbox from "./select_handler";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { create_order } from "../../services/portal/portal";
const customLocalization = {
  ...MRT_Localization_ES,
  toolbar: {
    ...MRT_Localization_ES.toolbar,
    resetOrder: 'Resetear Orden',
  },
};
const FORECAST_TABLE = () => {

  const notificationSystemRef = useRef();
  const [open, setopen] = useState(false);
  const [data, setData] = useState([]);
  const [keys, setkeys] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  var consulta = 0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [usStateList, setusStateList] = useState([]);
  const [total_rows, settotal_rows] = useState("");

  const [gvalor_en_mn, setgvalor_en_mn] = useState(0);

  const [porc, setporc] = useState(0.0);

  const [ff, setff] = useState(dayjs(date_current));
  const [fi, setfi] = useState(dayjs(previous_date));
  const [suplier, setsuplier] = useState(null);
  const [warehouse, setwarehouse] = useState(null);
  const [filter, setfilter] = useState("Dates");
  const [orderpg, setorderpg] = useState([]);
  const [option_aux, setoption_aux] = useState("FECHA");
  const [selectedColumns, setSelectedColumns] = useState({});
  const [order_forecast, setorder_forecast] = useState([]);



  const [query_filters, setquery_filters] = useState({});
  const [query_filters_dt, setquery_filters_data] = useState("");
  // useEffect(() => {
  //   handleSubmit();
  // }, []);



  const update_identifiers =
    [

    ];




  const update_null_identifiers = (original_obj, identifiers_to_update) => {
    return original_obj.map((item) => {
      for (const identifier in item) {
        if (item.hasOwnProperty(identifier)) {
          const isToUpdate = identifiers_to_update.some((update) => update[identifier] !== undefined);
          if (isToUpdate && item[identifier] === null) {
            item[identifier] = typeof identifiers_to_update.find((update) => update[identifier]) === 'number' ? 0 : '';
          }
        }
      }
      return item;
    });
  };

  //proceso de consultar todas las cuentas contables
  const handleSubmit = async (query_filters_flag, query_filters_data) => {

    setmodalT(true);
    setIsLoading(true);

    var data;

    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        setTimeout(async () => {
          try {

            var d;
            if (query_filters_flag) {
              setfi(dayjs(query_filters_data.datei))
              setff(dayjs(query_filters_data.datef))
              setoption_aux(query_filters_data.option_aux);
              setsuplier(query_filters_data.supplier);
              setwarehouse(query_filters_data.whscode);
              d = await get_forecast(query_filters_data);

              setquery_filters(query_filters_data)
            } else {

              data = {
                filtro: filter,
                datei: filter === "Dates" || filter === "SupplierAndDates" || filter === "WarehouseAndDates" ? fi?.format('YYYY-MM-DD') : null,
                datef: filter === "Dates" || filter === "SupplierAndDates" || filter === "WarehouseAndDates" ? ff?.format('YYYY-MM-DD') : null,
                supplier: suplier,
                whscode: warehouse,
                option_aux: option_aux
              }

              setquery_filters(data);

              d = await get_forecast(data);
            }

            // const d = await get_factor_importation(data);


            const weeks = Object.keys(d.data.info[0]).filter(key => key !== 'code');

            // Función para verificar si todos los valores de una semana específica son iguales a 0
            const allValuesZero = (week) => d.data.info.every(obj => obj[week] === 0);

            // Filtrar las semanas que deben eliminarse
            const weeksToDelete = weeks.filter(week => allValuesZero(week));

            // Filtrar los datos eliminando las semanas identificadas
            const filteredData = d.data.info.map(obj => {
              const newObj = { ...obj };
              weeksToDelete.forEach(week => delete newObj[week]);
              return newObj;
            });

            setData(filteredData);

            setmodalT(false);
            setIsLoading(false);
          } catch (err) {

            console.log("error", err);
            setData([]);
            setopen(false);
            setmodalGeneral(false);
            setmodalT(false);
            setIsLoading(false);
          }
        }, 1000);
      } else { }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };



  const handle_success_filter = (a, b) => {
    handleSubmit(a, b);
    notificationSystemRef.current.addNotification({
      message: <AlertResponse msj={"Filtro Aplicado"} view={true}></AlertResponse>,
      level: "success",
      position: "br",
      autoDismiss: 10,
    });

  }

  const handleColumnSelectionChange = (columnId) => {
    setSelectedColumns(prev => ({ ...prev, [columnId]: !prev[columnId] }));
  };




  const transformDataForSelectedColumns = (selectedColumns, tableData) => {
    const selectedColumnKeys = Object.keys(selectedColumns).filter(key => selectedColumns[key]);

    const orders = selectedColumnKeys.map(columnKey => {
      // Extraer el valor numérico antes del guion
      const weekNumber = columnKey.split('-')[0];

      return {
        week: Number(weekNumber), // Solo el valor numérico antes del guion
        details: tableData.map(row => ({
          code: row['code'],
          quantity: row[columnKey]
        }))
      };
    });

    return { orders };
  };


  useEffect(() => {
    const transformedData = transformDataForSelectedColumns(selectedColumns, data);
    setorder_forecast(transformedData)
  }, [selectedColumns, data]);



  const handle_create_order = async () => {
    try {
      setmodalT(true);

      await create_order(order_forecast);
      console.log(order_forecast)
      alert("Éxito, el pedido se generará en unos minutos.")
      setmodalT(false);
      window.location = "/FORECAST_TABLE"

    } catch (error) {
      alert("Error al generar el pedido");
      setmodalT(false);
      window.location = "/FORECAST_TABLE"

    }
  }


  const columns = useMemo(() => {
    return (data[0] ? Object.keys(data[0]) : []).map(key => ({
      id: key,
      enableHiding: true,
      enableSorting: false,
      accessorKey: key,
      header:
        <Fragment >
          <Box>
            {key === "code" ? "Código" : key}
          </Box>
          {key === "code" ? "" : <input type="checkbox" onClick={() => handleColumnSelectionChange(key)} style={{ cursor: 'pointer' }}></input>}
        </Fragment>
      ,
      size: 150,
      muiTableBodyCellProps: {
        align: "justify",
        style: selectedColumns[key] ? { backgroundColor: '#b8daffa1' } : {},
      },
      Footer: (d) => {
        const subtotal = d.table.getRowModel().rows.map(obj => obj.original).map(obj => obj[key]).filter(d => d !== "")
        const g_total = d.table.getCoreRowModel().rows.map(obj => obj.original).map(obj => obj[key]).filter(d => d !== "")
        return (
          <div style={{ fontSize: "14px", textAlign: "justify" }}>
            {key === "code" ? <div>Sub-Total</div> : <div>{calculate_total(subtotal)}</div>}

            {key === "code" ? <div>Total</div> : <div>{calculate_total(g_total)}</div>}

          </div>
        )
      },
    }));
  }, [data, selectedColumns]);



  useEffect(() => {
    if (total_rows === "") {
    } else {
      const getCurrentPath = () => {
        const search = window.location.search;
        const pathWithoutQuestionMark = search.startsWith('?') ? search.slice(1) : search;
        return pathWithoutQuestionMark;
      };

      const pathWithoutQuestionMark = getCurrentPath();
      setquery_filters_data(pathWithoutQuestionMark)
    }
  }, [total_rows])



  return (
    <>
      <BREADCRUMBS niveles={
        [
          { label: "FORECAST BRP", path: null },
        ]
      }
        // old_version={true}
        // modul="CONSULTAR_CUENTAS_CONTABLES_V1"
        // path="/consult_accounting_account"  
        cards_dashboard={true}
        total_rows={total_rows !== "" ? total_rows : 0}
        filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
        visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
        total={data?.length}
      ></BREADCRUMBS>
      <div className='new_table_v2'>
        <div >
          <NotificationSystem ref={notificationSystemRef} ></NotificationSystem>
          <MODAL_TABLE
            open={true}
            message={menssage}
            modalGeneral={modalGeneral}
            modalT={modalT}
          ></MODAL_TABLE>
          <>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              onStateChange={handleStateChange}
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={customLocalization}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={
                {
                  showGlobalFilter: true,
                }}
              enableRowNumbers={true}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important"} }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiTableFooterCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{
                color: 'primary', shape: 'rounded', variant: 'variant', rowsPerPageOptions: [10, 15, 30, 50, 100, 200, 1000],
              }}

              displayColumnDefOptions={{
                'mrt-row-pin': {
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}

              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    data.splice(
                      hoveredRow.index,
                      0,
                      data.splice(draggingRow.index, 1)[0],
                    );
                    setData([...data]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table);
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                // <FILTERS_FACTOR
                //   fi={fi}
                //   ff={ff}
                //   setfi={setfi}
                //   setff={setff}
                //   suplier={suplier}
                //   setsuplier={setsuplier}
                //   warehouse={warehouse}
                //   setwarehouse={setwarehouse}
                //   handleSubmit={handleSubmit}
                //   setfilter={setfilter}
                //   setoption_aux={setoption_aux}
                //   option_aux={option_aux}
                // />
                <div>
                  <Button
                    style={{ "backgroundColor": "green" }}
                    sx={{ height: '35px', marginRight: "5px", width: "100%", zIndex: 100, color: "white" }}
                    onClick={() => handle_create_order()}
                  >
                    <UploadFileIcon></UploadFileIcon>
                    Crear Pedido
                  </Button>
                </div>
              )}
              renderToolbarInternalActions={({ table, idenx }) => (
                <RENDER_TOOLBAR_INTERNAL_ACTIONS
                  table={table}
                  file_name={"Forecast"}
                  update_identifiers={update_identifiers}
                  gvalor_en_mn={gvalor_en_mn}
                  porc={porc}
                  query_filters={query_filters}
                  query_filters_dt={query_filters_dt}
                  handle_get_consult={handleSubmit}
                  key={idenx}
                  forecastFlag={true}
                  settotal_rows={settotal_rows}
                  handle_success_filter={handle_success_filter}
                  share_filter={false}
                  total_rows={total_rows !== "" ? total_rows : 0}
                />
              )
              }
            />

          </>
        </div>
      </div>
    </>
  );
}

export default FORECAST_TABLE;
