import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../../imgComponents/save.png";
import Delete from "../../../../../imgComponents/borrar.png";
import { refresh_token } from "../../../../../main_components/tokens/tokenrefresh";
import { delete_expense_concept_list, delete_expense_concepts } from "../../../../../services/expenses/expenses";

import OPERATIONS_ASSIGNED_CONCEPTS from "./op_assigned_concepts";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector =async () =>{
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if(rf_token===true){
                setTimeout(async() => {
                try{
                   const d = await delete_expense_concept_list(sessionStorage.getItem("id_catalogo"), props?.row.original.id);
                   console.log(d);
                   props.alertas("Catálogo de Conceptos de Gastos Eliminado", true);
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.handleSubmit();
                }catch(err){
                   props.setmodalT(false);
                   props.setreconsult(false);
                   props.alertas("Error, no se pudo eliminar el Catálogo de Conceptos de Gastos", false);
                }
                }, 1000);
            }else{}    
        } catch (err) {
        console.log(err);
        }
    }
   

    return (
        <div>
            <MenuItem
                key={1}
                onClick={ async() =>{
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar el Concepto?','Eliminar Concepto','Si','No');
                    if(result){
                        DeleteSector()
                        }
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Concepto
            </MenuItem>
            <MenuItem
                key={2}
                onClick={ async () =>{
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONS_ASSIGNED_CONCEPTS 
                             operation={"EDIT"} 
                             row={props?.row} 
                             handleSubmit={props?.handleSubmit} 
                             alertas={props?.alertas} 
                             setmodalT={props?.setmodalT}
                         />,
                    {
                        className: "custom-modal",
                        title: "EDITAR CONCEPTO",
                        showCloseIcon: true,
                    }
                    );
                    } 
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Concepto"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Concepto
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
