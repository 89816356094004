import { Confirm, CustomDialog } from "react-st-modal";
import { ListItemIcon, MenuItem } from '@mui/material';
import Edit from "../../../../imgComponents/save.png";
import Delete from "../../../../imgComponents/borrar.png";
import { refresh_token } from "../../../../main_components/tokens/tokenrefresh";
import { delete_expense_income_rules } from "../../../../services/expenses/expenses";
import OPERATIONS_RULES_SE from "./op_rules_se";

const RENDER_ROW_ACTION_MENU_ITEMS = (props) => {

    const DeleteSector = async () => {
        props.setmodalT(true);
        props.setreconsult(true);
        try {
            var rf_token = await refresh_token();
            if (rf_token === true) {
                setTimeout(async () => {
                    try {
                        const d = delete_expense_income_rules(props?.row?.original?.id);
                        console.log(d);
                        props.alertas("Regla de Gastos Eliminada", true);
                        props.setmodalT(false);
                        props.setreconsult(false);
                        props.handleSubmit();
                    } catch (err) {
                        props.setmodalT(false);
                        props.setreconsult(false);
                        props.alertas("Error, no se pudo eliminar la Regla de Gastos", false);
                    }
                }, 1000);
            } else { }
        } catch (err) {
            console.log(err);
        }
    }


    return (
        <div>
            <MenuItem
                key={1}
                onClick={async () => {
                    props.closeMenu();
                    const result = await Confirm('¿Esta usted seguro de eliminar la Regla de Gastos?', 'Eliminar Regla de Gastos', 'Si', 'No');
                    if (result) {
                        DeleteSector()
                    }
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Delete}
                        title="Eliminar Regla de Gastos"
                        className=""
                    ></img>
                </ListItemIcon>
                Eliminar Regla de Gastos
            </MenuItem>
            <MenuItem
                key={2}
                onClick={async () => {
                    props.closeMenu();
                    await CustomDialog(
                        <OPERATIONS_RULES_SE
                            operation={"EDIT"}
                            row={props?.row}
                            handleSubmit={props?.handleSubmit}
                            setmodalT={props?.setmodalT}
                            alertas={props?.alertas}
                        />,
                        {
                            className: "custom-modal",
                            title: "EDITAR REGLA DE GASTOS",
                            showCloseIcon: true,
                        }
                    );
                }
                }
                sx={{ m: 0 }}
            >
                <ListItemIcon>
                    <img
                        alt=""
                        src={Edit}
                        title="Editar Regla de Gastos"
                        className=""
                    ></img>
                </ListItemIcon>
                Editar Regla de Gastos
            </MenuItem>
        </div >
    );
};

export default RENDER_ROW_ACTION_MENU_ITEMS;
